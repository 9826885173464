@import "bourbon/bourbon";
@import "neat/neat";
@import "base/base";
@import "chartist/chartist";
@import "lib/lib";
@import "charts/charts";

@import "base/variables";
@import "base/mixins";

@import "base/grid-settings";

@import "base/forms";
@import "base/form-groups";
@import "base/lists";
@import "base/tables";
@import "base/typography";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;

  @include bodyBackground;

  overflow-x: hidden;
}
