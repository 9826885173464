@use "sass:math";
@use '../../../scss/themes/mixins' as themedMixins;
@import '../../../scss/base/variables';
@import '../../../scss/lib/animations';

.ctaButtonContainer {
  align-items: center;
  background-color: var(--brand-surface-elevation-500);
  @include themedMixins.neutralDown-500;
  border-radius: $medium-border-radius;
  cursor: pointer;
  display: flex;
  min-height: 75px;
  justify-content: space-between;
  padding: $xs-spacing 20px $xs-spacing 28px;

  &:active {
    animation: button-pop math.div($base-duration, 3);
  }

  .navIcon {
    color: var(--brand-secondary-600);
    font-weight: 700;

    &.locked {
      color: var(--brand-hb-white);
    }
  }

  .blur {
    filter: blur(3.5px);
  }

  &:hover {
    background-color: var(--brand-surface-elevation-600);
    transform: scale3d(1.02, 1.02, 1);
    @include themedMixins.neutralDown-600;

    .navIcon {
      color: var(--brand-secondary-500);

      &.locked {
        color: var(--brand-hb-white);
      }
    }
  }

  .ctaText {
    color: var(--brand-neutral-cool-700);
    font-size: 16px;
    line-height: normal;
    margin: 0 15px 0 0;
    text-align: left;
    white-space: normal;

    em {
      color: var(--brand-hb-white);
      font-weight: $weight-semibold;
    }
  }
}
