@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';
@import '../Button/CloseButton.module.scss';

.wrapper {
  z-index: $z-3;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.level4 {
    z-index: $z-ceiling;
  }

  &.allowScroll {
    overflow-y: auto;
  }
}

.backdrop {
  z-index: $z-3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--brand-hb-black);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  opacity: .75;

  &.level4 {
    z-index: $z-ceiling;
  }
}

$sizes: 'm' 600px, 'l' 750px;
$border-top-width: 5px;

.container {
  color: var(--brand-neutral-cool-900);
  border-radius: $large-border-radius;
  z-index: $z-3;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  border-top: $border-top-width solid var(--brand-success-700);
  border-right: 1px solid var(--brand-primary-border-50);
  border-bottom: 1px solid var(--brand-primary-border-50);
  border-left: 1px solid var(--brand-primary-border-50);
  background-color: var(--brand-surface-elevation-500);

  @include themedMixins.neutralDown-800;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include smallScreen {
    width: 600px;
    max-width: 100%;
  }

  &.level4 {
    z-index: $z-ceiling;
  }

  &.error {
    border-top-color: var(--brand-warning-500);
  }

  &.quiet {
    border-top-color: var(--brand-neutral-cool-600);
  }

  &.warn {
    border-top-color: var(--brand-primary-600);
  }

  &.none {
    border-top: 1px solid var(--brand-primary-border-50);
  }

  @each $size in $sizes {
    &.size-#{nth($size, 1)} {
      width: nth($size, 2);
    }
  }

  &.withProgress {
    overflow-y: visible;
  }

  .progress {
    position: absolute;
    top: -$border-top-width;
    left: 0;
    height: $border-top-width;
    background-color: var(--brand-primary-600);
  }
}

.header {
  padding: $small-spacing $base-spacing;

  @include mediumScreen {
    padding: $large-spacing $xl-spacing $base-spacing;
  }

  &.dark {
    background-color: var(--brand-surface-layer-300);
  }

  &.hasTabs {
    padding-bottom: 0 !important;
  }

  .error & {
    display: none;
  }
}

.inner {
  // background-color: var(--brand-surface-layer-50);
  text-align: center;
  padding: $base-spacing $base-spacing;

  @include smallScreen {
    padding: $base-spacing $xl-spacing;
  }

  @include mediumScreen {
    padding: $large-spacing $xl-spacing;
  }
}

.title {
  margin-top: 1em;

  @include mediumScreen {
    margin-top: 0;
  }
}

.subtitle {
  margin-top: $small-spacing;

  @include mediumScreen {
    margin-top: $base-spacing;
  }
}

.close {
  @include closeButtonTopRight;
}
