.has-graph .number {
  position: relative;
  z-index: 1;
}
.graph-pie .ct-label {
  fill: white;
  font-size: em(14);
  font-weight: bold;
  text-shadow: 0 1px 1px $shade-03;
}

.graph-bar {
  .ct-chart-bar {
    // chartist has sizing issues on simpled unlabeled graphs
    margin: 0 -10%;
    padding-left: 10%;
    width: 120% !important;
  }

  .ct-bar {
    stroke-width: $xxl-spacing;
  }
}

.graph-avm-compare {
  .ct-series-a {
    @include ct-chart-series-color($brand-zillow);
  }

  .ct-series-b {
    @include ct-chart-series-color($brand-core-logic);
  }

  .ct-series-c {
    @include ct-chart-series-color($brand-realty-trac);
  }
}
