@import '../../../../../../scss/base/variables';
@import '../../../../../../scss/buyers_mixins';

.top {
  flex: 0;
}

.top:nth-child(2) {
  margin-top: $base-spacing;
}

.searchType {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 9px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  svg {
    margin-right: $xs-spacing;
  }
}

.subHeader {
  letter-spacing: 2px;
}

.listContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: $xs-spacing 0 0 20px;
}

.list {
  margin-top: $xs-spacing;
}
