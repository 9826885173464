@use '../../../scss/themes/mixins' as themedMixins;
@import '../../../scss/base/variables';

.appLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sectionLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  border-radius: $large-border-radius;
  background-color: var(--brand-surface-elevation-300);
  color: var(--brand-neutral-cool-900);
  @include themedMixins.neutralDown-400;
  transition: all 0.35s ease-out;
  margin-bottom: 3em;
}

.blockLoader {
  margin: 1em;
  text-align: center;

  .loader {
    margin: 0 auto;
  }
}

.headline {
  margin-bottom: 1em;
}

.loader {
  font-size: $small-font-size;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 100%;
  height: 85%;
  min-height: 40px;

  &.absolute {
    position: absolute;
  }
}

.loadingDescribe {
  opacity: 0;
  position: absolute;
}
