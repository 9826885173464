@use '../../../../scss/themes/mixins' as themedMixins;

@import '../../../../scss/base/variables';
@import '../../Panel/variables.module.scss';

.headerBar {
  background-color: var(--brand-surface-elevation-600);
  @include themedMixins.neutralDown-600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  width: 100%;
  font-size: $l-font-size;
  z-index: 10;
  padding: 0;

  @media screen and (max-width: 600px) {
    padding-right: 0 !important; // remove the padding for the scrollbar width once this header takes up the full screen width
  }

  $backButton-bump: 30px;
  $backButton-breakPoint: calc(#{$content-width-max} + 2 *#{$backButton-bump});
  $backButton-left: calc(
    (100vw - #{$content-width-max}) / 2 - #{$backButton-bump}
  );

  .headerBarContents {
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-align: left;
  }

  .headerBarProgressBackground {
    background: var(--brand-neutral-cool-600);
    width: 100%;
    height: 4px;
  }

  .headerBarProgress {
    background: var(--brand-primary-500);
    height: 4px;
  }

  .headerBarLeft,
  .headerBarRight {
    flex: 0 1 60px;

    @media screen and (min-width: $content-width-max) {
      flex-basis: 115px;
    }
  }

  .headerBarCenter {
    color: var(--brand-neutral-cool-900);
    flex: 1 1 auto;
    align-self: center;
    text-align: center;
  }

  .headerBarBreak {
    flex-basis: 100%;
    height: 0;
  }

  .backButton {
    flex: 0 1 auto;
    height: 100%;
    min-width: 60px;
    top: 0;
    left: 0;
    color: var(--brand-neutral-cool-700);
    border: none;
    border-radius: 0;
    background-color: transparent;

    &:hover {
      background-color: var(--brand-neutral-cool-100);
      color: var(--brand-neutral-cool-900);
      fill: var(--brand-neutral-cool-900);
    }

    &:focus {
      outline-color: var(--brand-neutral-cool-700);
      color: var(--brand-neutral-cool-900);
      fill: var(--brand-neutral-cool-900);
    }

    .buttonInner {
      display: flex;
      align-items: center;

      .icon {
        font-size: 1.25em;
        margin-top: 2px;
      }

      .backButtonText {
        display: none;
        margin: 0 1em;

        @media screen and (min-width: $content-width-max) {
          display: inline;
        }
      }
    }
  }
}
