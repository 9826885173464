@import 'base/variables';

// TODO: split into files

// Typography helpers
@mixin xxs-label {
  @include font-xxs;
  color: var(--brand-neutral-cool-700);
  font-weight: $weight-semibold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin small-label {
  color: var(--brand-neutral-cool-700);
  font-size: 12px;
  font-weight: $weight-semibold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin small-label-description {
  @include font-small;
  color: var(--brand-neutral-cool-700);
  font-weight: $weight-regular;
}

@mixin font-xxs {
  font-size: 10px;
}

@mixin font-xs {
  font-size: 11px;
}

@mixin font-small {
  font-size: 13px;
}

@mixin truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Dimensional helpers
@mixin size($w, $h) {
  width: $w;
  height: $h;
}

@mixin square($dim) {
  width: $dim;
  height: $dim;
}

@mixin chartContainer() {
  overflow: hidden;
  padding-bottom: 40%;
  position: relative;
  vertical-align: top;

  > svg {
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
  }
}

@mixin chartContainerLineChart() {
  padding-bottom: 40%;
}

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top': 'bottom',
    'right': 'left',
    'bottom': 'top',
    'left': 'right',
    'center': 'center',
    'ltr': 'rtl',
    'rtl': 'ltr',
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append(
        $opposite-directions,
        unquote(map-get($direction-map, $direction))
      );
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentColor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: $size;
  height: $size;
  position: absolute;
  content: '';
  border-#{opposite-direction($direction)}: $size solid $color;
  #{opposite-direction($direction)}: 100%;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
    left: 50%;
    margin-left: -($size);
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
    top: 50%;
    margin-top: -($size);
  }
}

@mixin card-padding {
  padding: $card-pad-vertical $card-pad-horizontal;

  @media (min-width: $medium-screen) {
    padding: $card-pad-vertical-lg $card-pad-horizontal-lg;
  }
}

@mixin card-pad-horizontal {
  padding-left: $card-pad-horizontal;
  padding-right: $card-pad-horizontal;

  @media (min-width: $medium-screen) {
    padding-left: $card-pad-horizontal-lg;
    padding-right: $card-pad-horizontal-lg;
  }
}

@mixin stretch-to-card-sides {
  margin-left: (-$card-pad-horizontal);
  margin-right: (-$card-pad-horizontal);

  @media (min-width: $medium-screen) {
    margin-left: (-$card-pad-horizontal-lg);
    margin-right: (-$card-pad-horizontal-lg);
  }
}

@mixin stretch-to-card-top {
  margin-top: (-$card-pad-vertical);

  @media (min-width: $medium-screen) {
    margin-top: (-$card-pad-vertical-lg);
  }
}

@mixin stretch-to-card-bottom {
  margin-bottom: (-$card-pad-vertical);

  @media (min-width: $medium-screen) {
    margin-bottom: (-$card-pad-vertical-lg);
  }
}

@mixin xlScreen($breakpoint: $xl-screen) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin largeScreen($breakpoint: $large-screen) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin mediumScreen($breakpoint: $medium-screen) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin smallScreen($breakpoint: $small-screen) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin landscapeSmall() {
  @media screen and (max-height: $small-screen) {
    @content;
  }
}

@mixin mediaBreakpointBetween($lowerBound, $upperBound) {
  @media (min-width: $lowerBound) and (max-width: calc($upperBound - 0.02px)) {
    @content;
  }
}

$mobileHeightMedia: 'screen and (max-height: 824px)';
@mixin mobileHeight() {
  // Catch for mobile phones
  @media #{$mobileHeightMedia} {
    @content;
  }
}

@mixin hPadding($v) {
  padding-left: $v;
  padding-right: $v;
}

:export {
  mobileHeight: #{$mobileHeightMedia};
}

@mixin hMargin($v) {
  margin-left: $v;
  margin-right: $v;
}

@mixin googleMapStyles {
  padding: $xs-spacing;
  z-index: 1;
  position: absolute;
  cursor: pointer;
  background-color: var(--brand-hb-white);
  @include square(40px);
  border-radius: 0;

  &:hover {
    background-color: var(--brand-hb-white);

    > svg > path {
      fill: var(--brand-hb-black);
    }
  }

  > svg {
    height: 30px;
    width: 30px;
  }

  > svg > path {
  fill: #666; // matches googles button
  }
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin fadeIn {
  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @include animate(fadeIn, 0.65s, linear, 1);
}

@mixin fadeOut {
  @include keyframes(fadeOut) {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @include animate(fadeOut, 0.65s, linear, 1);
}
