.modal {
  @include position(fixed, 0 0 0 0);
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: $z-modal;

  .modal-inner {
    @include card-base;
    margin: 10% auto;
    opacity: 0;
    position: relative;
    transform: translateY(-10%);
    transition: opacity $base-duration $base-easing,
      transform $base-duration $base-easing;

    @media (min-width: $medium-screen) {
      max-width: $content-width-max * 0.8;
    }
  }

  .modal-inner--legal {
    margin-top: 8%;
  }

  &.visible {
    display: block;
  }

  .button-group {
    margin-bottom: 0;
  }
}

.modal-wide .modal-inner {
  @media (min-width: $medium-screen) {
    max-width: $large-content-width-max;
  }
}

.modal-full .modal-inner {
  max-width: 90%;
}

.modal-light .modal-inner {
  background: $white;
}

.modal--standalone {
  .modal-inner {
    background: none;
    box-shadow: none;
    padding: 0;
  }
}

.modal-backdrop {
  @include position(fixed, 0 0 0 0);
  background: $shade-14;
  content: '';
  display: none;
  opacity: 0;
  z-index: $z-modal - 1;
  transition: opacity $base-duration $base-easing,
    transform $base-duration $base-easing;

  &.visible {
    display: block;
  }
}

body.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-backdrop {
    opacity: 1;
    transition: opacity $xl-duration $base-easing,
      transform $xl-duration $base-easing;
  }

  .modal.visible {
    .modal-inner {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.modal-error {
  .icon-bot-head-circle {
    color: $white;
    transition: transform $xl-duration $base-easing, color $xl-duration;
  }
}

.modal-open .modal .icon-bot-head-circle {
  color: $brand-danger;
  transform: rotate(145deg);
}
