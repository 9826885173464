.notice {
  text-align: center;

  p {
    @include font-small;
    text-align: center;
  }

  > .icon {
    display: inline-block;
    font-size: 42px;
    margin: $xs-spacing auto;
  }
}
