@import '../base/variables';

.mapboxgl-map {
  .mapboxgl-ctrl-attrib {
    display: none;
  }

  .mapboxgl-ctrl-bottom-left {
    .mapboxgl-ctrl-logo {
      display: none;
    }
  }
}
