body.spotlight-open {
  overflow-y: hidden;

  @media (min-width: $medium-screen) {
    overflow-y: visible;
  }
}

.spotlight {
  @include nav-dropdown;

  li {
    border-bottom: 2px solid $dark-blue;
    color: $light-gray;
    line-height: $base-line-height;
    padding: $small-spacing $xs-spacing $small-spacing $xl-spacing;
    position: relative;
    text-align: left;

    &:before {
      $size: 10px;
      @include position(absolute, 33% auto 0 $xl-spacing*0.5);
      @include square($size);
      content: '';
      margin-left: (-$size*0.5);
      border-radius: 10em;
    }

    &:hover {
      background-color: $darkish-blue;
    }

    .notification-description {
      color: $white;
      font-size: $small-font-size;
    }

    .notification-date {
      font-size: $xs-font-size;
    }
  }

  li.seen::before {
    border: 1px solid $brand-quiet;
  }

  li.unseen::before {
    background-color: $action-color;
  }
}
