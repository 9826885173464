@use '../../../../scss/base/variables' as vars;

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: vars.$nav-header-height;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
