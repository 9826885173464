@use '../../../../scss/base/variables' as vars;
@use '../../../../scss/buyers_mixins' as bm;

.root {
  @include bm.square(40px);
  position: relative;
  padding: 3px;

  &:before {
    content: close-quote;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 3px solid var(--brand-neutral-cool-300);
    border-radius: 100%;
    border-left-color: var(--brand-hb-homebot);
    animation: rotate 1.5*vars.$base-duration infinite ease-in;
  }

  &.inPanel {
    @include bm.square(60px);
    margin: 15vh auto 20vh;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
