@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

.root {
  margin-bottom: 0;
}

.labelClass {
  display: inline-flex;
  align-items: center;
}

.text {
  order: 2;
}

.input {
  order: 1;
}
