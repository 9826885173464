@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.container {
  margin-top: 1.75rem;
  text-align: center;
}

.header {
  width: 75%;
  margin: 0 auto;
}

.headline {
  color: var(--brand-neutral-cool-900);
  font-weight: 700;
}

.sponsorBranding, .sponsorVideo, .cospoMessage {
  margin-bottom: $large-spacing;
}

.sponsorVideo {
  margin-left: $small-spacing;
  margin-right: $small-spacing;
}

.agentContainer {
  position: relative;
  background: var(--brand-surface-layer-200);
  border-radius: $medium-border-radius; 
}

.avatar {
  @include square(70px);

  position: absolute;
  transform: translate(-50%, -50%);
}

.agentContact {
  padding: 0 24px $base-spacing;
}

.agentSocial {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & > * {
    flex: 1 1 0px;
    max-width: 225px;
    margin: 0 $medium-spacing;
  }

  a {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5;
  }

  @media screen and (min-width: $small-screen) {
    flex-direction: row;
  }
}

.divider {
  margin-block-start: 3px;
  margin-block-end: 3px;
}

.cospo {
  margin-top: $base-spacing;
} 

.email {
  padding: 0 !important;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
  font-size: $small-font-size;
}

.phone {
  padding: 0 !important;
  font-size: $small-font-size;
}

.questions {
  @media screen and (min-width: $medium-screen) {
    display: block;
    width: 100%;
  }

  > :not(:last-child) {
    margin-bottom: $medium-spacing;
  }

  > :last-child {
    margin-bottom: $xl-spacing;
  }
}

.messageContainer {
  padding-top: 33px;
}

