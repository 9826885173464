@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.container {
  text-align: center;
  position: relative;
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
