@use '../../../scss/base/variables' as vars;
@use '../../../scss/buyers_mixins' as buyers;

.toasts {
  position: fixed;
  box-sizing: border-box;
  width: calc(100% - vars.$base-spacing);
  left: calc(vars.$base-spacing / 2);
  bottom: vars.$base-spacing;
  z-index: 100;

  @include buyers.mediumScreen {
    left: vars.$large-spacing;
    width: 242px;  
    bottom: vars.$base-spacing;
  }  
}
