@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.root {
  position: relative;

  &.spin {
    display: flex;
    justify-content: center;
    align-content: center;

    .spinner {
      margin-top: 20%;
    }

    iframe {
      visibility: hidden;
    }
  }
}

.ytContainer {
  position: relative;
  padding: 62.5% 0 0;

  .iframe {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.errorMessageContainer {
  position: relative;
  padding: 62.5% 0 0;
  overflow: hidden;

  .errorMessage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
