@use '../../../../scss/base/variables' as vars;
@use '../../../../scss/buyers_mixins' as mixins;

@mixin activeListItem {
  outline: none;
  border-left: 4px solid var(--brand-secondary-600);
  background: var(--brand-surface-elevation-600);

  &.lightTheme {
    background: var(--brand-secondary-50);
  }

  & > div > svg > path {
    fill: var(--brand-secondary-600);
  }

  & > span {
    color: var(--brand-secondary-600);
  }
}

@mixin activeListItemDesktop {
  outline: none;
  border-left: 4px solid var(--brand-neutral-cool-900);
  background: var(--brand-surface-elevation-600);

  &.lightTheme {
    background: var(--brand-secondary-50);
    color: var(--brand-secondary-600);
  }

  & > div > svg > path {
    fill: var(--brand-neutral-cool-900);
  }

  & > span {
    color: var(--brand-neutral-cool-900);
  }
}

.listItem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 4px solid transparent;
  padding: vars.$xs-spacing vars.$medium-spacing;
  transition: all 0.2s;

  & > svg {
    margin-right: vars.$xs-spacing;
  }

  & > span {
    padding: vars.$xs-spacing;
  }

  &:focus,
  &:hover {
    @include activeListItem
  }
}

.isActive {
  @include activeListItem;

  @include mixins.mediumScreen {
    @include activeListItemDesktop
  }
}
