@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';
@import '../../../scss/buyers_mixins';
@import '../Panel/variables.module.scss';

.overlayAnchor {
  position: relative;
}

.overlayCurtain {
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  overflow-y: scroll;
  @include bodyBackground;

  &.hidden {
    visibility: hidden;
  }
}

.overlayPanel {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $content-width-max;
  margin: 0 auto;
  align-items: stretch;
  min-height: 90vh;
  text-align: center;
  padding-top: $nav-header-height;
  padding-bottom: 4.5em;

  @include mobileHeight {
    min-height: 100%;
  }

  @media (max-width: $medium-screen) {
    border-radius: 0;
  }
}