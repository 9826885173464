@use '../../../scss/base/variables' as vars;
@use '../../../scss/themes/mixins' as themedMixins;
@use '../../../scss/buyers_mixins' as mixins;

$border-width: 5px;

.tooltip {
  position: relative;
  display: inline-block;
  width: max-content;
  background-color: var(--brand-surface-elevation-300);
  border-radius: vars.$small-border-radius;
  padding: vars.$small-spacing;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -$border-width;
  border-width: $border-width;
  border-style: solid;
  border-color: transparent transparent var(--brand-surface-elevation-300) transparent;
}
