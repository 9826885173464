// TODO: update all .number's to be
// .number-row > .number structure
// (.number shouldn't determine layout anymore)

.number {
  @include number;
  line-height: 1.2em;
  margin-bottom: 0.3em;
}

a.number {
  &:link,
  &:focus,
  &:hover,
  &:active {
    color: var(--brand-primary-600);
  }
}

.number-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: $small-spacing;

  .number {
    margin-bottom: 0;
  }

  button,
  .button {
    font-size: 14px;
    flex: 0 0 auto;
    margin: $small-spacing auto 0;
  }

  @media (min-width: $medium-screen) {
    flex-direction: row;

    button,
    .button {
      margin: 0 -8.5em 0 $small-spacing;
    }
  }
}

.number-small {
  font-size: em(36);
}
