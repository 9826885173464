/*
* IN GENERAL, DO NOT REPEAT THIS APPROACH FOR CUSTOM CLASSES
*
* We don't have custom classes in base styling, we target element selectors like a, p, fieldset, etc
* This has such a wide spread use, and need for flexibility that we went made it globally available
*
* It's industry standard and widely used for accessibility alongside a scss module pattern, which is what we do!
*/
.screenReaderText {
  clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
}
