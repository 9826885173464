@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$border-top-height: 36px;
$custom-color: var(--brand-primary-custom, var(--brand-primary-600));

.root {
  max-width: 600px;
  position: relative;
  margin: auto;
  text-align: center;
}

abbr {
  text-decoration: none;
}

.avatarContainer {
  display: inline-block;
  line-height: 0;
  position: absolute;
  top: calc($border-top-height / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  & > .avatarShifted {
    @include themedMixins.neutralDown-300;
    @include square(72px);

    @include mediumScreen {
      @include square(96px);
    }

    border: 3px solid var(--brand-neutral-cool-900);
  }

  & > .avatarShifted:first-child {
    margin-right: calc(-1 * #{$xs-spacing});
  }

  & > .avatarShifted:not(:first-child) {
    margin-left: calc(-1 * #{$xs-spacing});
  }
}

.container {
  position: relative;

  background: var(--brand-surface-elevation-200);
  border-top: $border-top-height solid $custom-color;
  border-right: 1px solid var(--brand-primary-border-50);
  border-bottom: 1px solid var(--brand-primary-border-50);
  border-left: 1px solid var(--brand-primary-border-50);
  border-radius: $large-border-radius;

  margin-top: $base-spacing;

  @include themedMixins.neutralDown-600;
}

.nameContainer {
  margin-top: $xxl-spacing;
}

.companyName {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.noteContainer {
  position: relative;
  margin: 0 $small-spacing;

  padding: $base-spacing;
  border: 1px solid var(--brand-primary-border-50);
  border-radius: $large-border-radius;

  @include mediumScreen {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 48px;
  width: 48px;
  background-color: $custom-color;
  border-radius: 50%;
  border: 1px solid rgba(251, 248, 250, 0.2);
  left: calc(50% - 48px / 2);
  top: calc(0% - 48px / 2);
}

.noteContent {
  margin-top: $small-spacing;
}

.note {
  margin-top: $xs-spacing;
  text-align: center;
  font-style: italic;
}

.topoContainer {
  display: grid;
  place-items: center;
  position: relative;

  margin: $base-spacing 0;
  height: 234px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--brand-primary-custom, var(--brand-primary-600));
    opacity: 0.05;
  }
}

.topoSvg {
  opacity: 30%;

  > path {
    fill: $custom-color;
  }
}

.officeContainer {
  @include themedMixins.neutralDown-400;
  z-index: 1;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 320px;
  margin: auto $medium-spacing;

  padding: $small-spacing $base-spacing;
  background-color: var(--brand-surface-elevation-200);
  border-radius: $medium-border-radius;
  border: 1px solid var(--brand-primary-border-50);
}

.officeNameAndNMLS,
.officeAddress {
  margin-bottom: $small-spacing;
}

.officePhone {
  font-size: $small-font-size;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding-right: $medium-spacing;
  padding-left: $medium-spacing;
}

.rowContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: $small-spacing 0;

  a {
    max-width: 100%;
  }

  dd {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5;
  }

  .customerStateLicense {
    overflow-wrap: anywhere;
    white-space: normal;
    text-align: start;
  }
}

// Hack to be more specific than minimal button class
.contactButton.contactButton {
  padding: 0px;
}
