@use '../../../scss/themes/mixins' as themedMixins;
@use '../../../scss/base/variables' as vars;

$listing-margin: 5px;
$image-width: 220px;
$image-height: 118px;

.listingCarousel {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: -82px;
  margin-right: -82px;

  @media screen and (max-width: 860px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  [class='slick-track'] {
    padding-top: .125rem;
    padding-bottom: .125rem;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.buttonParent {
  @media screen and (min-width: 500px) and (max-width: 749px) {
    width: $image-width - ($listing-margin * 2);
  }

  display: inline-block;
  @include themedMixins.neutralDown-300;
  border-radius: vars.$medium-border-radius;
  transition: all 0.15s ease-out;
  overflow: hidden;
  margin: 0 $listing-margin;
  font: unset;
  padding: 0;
  border: 0;
  cursor: pointer;
  background-color: var(--brand-surface-elevation-600);
  white-space: normal;
  height: 100%;

  &:hover {
    background-color: var(--brand-surface-elevation-700);
    @include themedMixins.neutralDown-700;
    transform: scale3d(1.01, 1.01, 1.01);
  }

  &:focus {
    background-color: var(--brand-surface-elevation-700);
    @include themedMixins.neutralDown-700;
    outline: 2px solid var(--brand-secondary-600);
  }
}
