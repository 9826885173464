@import '../../../scss/base/variables';

.wrapper {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all $base-easing $base-duration, opacity $base-easing 2*$base-duration;

  &.start {
    position: relative;
    margin-bottom: 0;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    margin-bottom: $xl-spacing;
  }

  &.extraWide {
    margin-bottom: $large-spacing;

    @media (min-width: 730px) {
      margin-left: -65px;
      margin-right: -65px;
    }
  }
}