@use '../../../scss/base/variables' as vars;

@keyframes selectedAnimation {
  0% {
    border: 1px solid transparent;
    box-shadow: 0 0 10px transparent;
  }

  25% {
    border: 1px solid var(--brand-secondary-400);
    box-shadow: 0 0 10px var(--brand-secondary-400);
  }

  75% {
    border: 1px solid var(--brand-secondary-400);
    box-shadow: 0 0 10px var(--brand-secondary-400);
  }

  100% {
    border: 1px solid transparent;
    box-shadow: 0 0 10px transparent;
  }
}

.selected {
  transition: box-shadow 0.3s ease-in-out;
  animation-name: selectedAnimation;
  animation-duration: 7s;

  &:focus,
  &:hover {
    border: 1px solid #444;
  }
}