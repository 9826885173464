.button-group {
  margin-bottom: $base-spacing;
  text-align: center;

  button,
  .button,
  .action-secondary {
    @include fixed-space-between($small-spacing);
    margin-bottom: $small-spacing;
  }

  > .action-secondary {
    position: relative;
    top: -0.25em;
  }

  .button-previous {
    float: left;
    margin-right: -2.5em; // TODO: make this less lame
  }

  .button-next--center {
    .icon {
      margin-right: 0;
      padding-left: $xs-spacing;
    }
  }
}

.button-group-left {
  text-align: left;
}

h3 + .button-group,
p + .button-group {
  margin-top: $base-spacing;
}

.button-group--multi-button .button-group-inner {
  display: flex;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $medium-screen) {
    max-width: 90%;
  }

  .button {
    flex: 1 1 0%;
    margin-top: 0;
  }
}
