@import "../../../scss/base/variables";
@import "../Input/control_variables.module";
@import "../../../scss/buyers_mixins";

$offset: 0.5em;
$select-paddingR: $offset * 3;
@mixin background($url) {
  background: var(--brand-surface-layer-400) $url no-repeat right $offset top
    50%;
}

.outerContainer {
  position: relative;
}

.root {
  position: relative;
}

.select {
  display: block;
  @include control;
  @include truncate;
  @include background(url("#{$assets_base}/img/caret.svg"));
  padding-right: $select-paddingR;
  appearance: none;
  width: 100%;
  border-radius: $medium-border-radius;
  border: 1px solid var(--brand-primary-border-100);

  // In Apple's signature style, they define their own web standards and we've got to support them.
  // Any input or select must render at <= 16px font or iOS will force zoom for accessibility.
  // !important enforces default, can be overriden with subsequent !important on specific implementation if required.
  // Also, max(16px, 1.5em) would be better but not suported well atm [4-5-21]
  font-size: 16px !important;

  // IE Hacks
  &::-ms-expand {
    display: none;
  }
  &:focus::-ms-value {
    background: $input-background-color;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--brand-surface-layer-700);
  }
}

.inline {
  display: inline-block;
  @include background(url(#{$assets_base}img/dropdown-arrow.svg));
  color: var(--brand-primary-600);
  line-height: 1.6em;
  padding: 0 $select-paddingR 0 $xs-spacing;
}

.option {
  color: var(--brand-hb-black);
}
