@import "../../../scss/base/variables";

.nameStepContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: $base-spacing;
}

.headline {
  margin-bottom: $xs-spacing;
}

.email {
  margin-top: $medium-spacing;
}

.forms {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: $xl-spacing;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
}
