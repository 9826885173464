@use '../../../../scss/base/variables' as vars;
@use '../../../../scss/buyers_mixins' as mixins;
@use "src/scss/themes/mixins" as themedMixins;

@mixin baseButtonPseudoStyles {
  animation: none;
  box-shadow: none;
  outline: none;
  color: var(--brand-secondary-600);
}

@mixin buttonHovered {
  @include baseButtonPseudoStyles;

  background-color: var(--brand-surface-elevation-600);

  &.lightTheme {
    background-color: var(--brand-neutral-cool-100);
  }
}

@mixin buttonActive {
  @include baseButtonPseudoStyles;

  background-color: var(--brand-surface-elevation-400);

  &.lightTheme {
    background-color: var(--brand-neutral-cool-50);
  }
}

.wrapper {
  cursor: pointer;
  height: inherit;
}

.button {
  padding: vars.$small-spacing vars.$medium-spacing;
  border-radius: vars.$xl-border-radius;
  background-color: var(--brand-surface-elevation-50);
  font-weight: vars.$weight-semibold;

  & > svg {
    display: flex;
    margin-top: 4px;
    margin-left: vars.$xs-spacing;
    transition: all 0.3s;
  }

  &:hover {
    @include buttonHovered;
  }
}

.dropdownIsOpen {
  @include buttonHovered;

  & > svg {
    rotate: 180deg;
  }
}

.dropdownItemIsActive {
  @include buttonActive;
}

.dropdownToggle {
  width: fit-content;
  min-width: 200px;
  max-width: 300px;
  top: vars.$global-nav-height;
  background-color: var(--brand-surface-elevation-50);
  border: 1px solid var(--brand-primary-border-200);
  border-radius: vars.$medium-border-radius;
  left: calc(0% - 0.36em);
  box-shadow: vars.$large-space-shadow;
}

@mixin baseTriangle {
  width: 0px;
  height: 0px;
  margin: 0px;
  position: absolute
}

.triangle {
  &:before {
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 11px solid var(--brand-primary-border-200);
    margin: 0px;
    top: -12px;
    right: 70%;

    @include baseTriangle;
  }

  &:after {
    content:"";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid var(--brand-surface-elevation-50);
    top: -10px;
    right: 70.5%;

    @include baseTriangle;
  }
}

.list {
  margin: vars.$xs-spacing 0;
}
