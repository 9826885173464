@use "src/scss/base/variables" as vars;

.loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: vars.$small-spacing
}

.results {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.center {
  flex: 1;
}
