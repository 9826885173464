.page-loader {
  @include position(fixed, 0 0 0 0);

  align-items: flex-end;
  display: flex;

  div {
    background: $brand-xs-quiet;
    flex: 1;
    height: 20%;
    font-size: 50px;
    margin-right: 1%;
    opacity: 0;
    transition: opacity $base-easing $base-duration;

    &.animated {
      animation: loading-bar 2.5s infinite linear;
      opacity: 0.1;
    }
  }
}

.page-loading {
  @include position(absolute, 25% auto auto auto);
  text-align: center;
  width: 100%;
  z-index: $z-modal;
}
