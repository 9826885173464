@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';
@import './variables.module.scss';

.root {
  background-color: var(--brand-surface-elevation-300);
  @include themedMixins.neutralDown-600;
  transition: all $base-duration $base-easing;
  width: 100%;
  @include panelPaddingTop;
  @include panelPaddingH;
  @include panelPaddingBottom;
  border-radius: $large-border-radius;
  border: 1px solid var(--brand-primary-border-50);
}

.footer {
  @include escapePanelPaddingH;
  @include escapePanelPaddingBottom;
  background-color: var(--brand-surface-layer-400);
  padding: $base-spacing $large-spacing;
  border-radius: 0 0 $large-border-radius $large-border-radius;

  @include mediumScreen {
    padding: $base-spacing;
  }
}

.section {
  @include escapePanelPaddingH;

  position: relative;
  height: auto;
  overflow: hidden;
  padding-top: $base-spacing;
  opacity: 1;
  transition: all $base-duration $base-easing;
}

.hidden {
  height: 0;
  opacity: 0;
  padding-top: 0;
}

.closeButton {
  position: absolute;
  right: $small-spacing;
  top: $base-spacing + $xxs-spacing;
}

.content {
  @include themedMixins.neutralDown-500;
  height: 100%;
  background-color: var(--brand-surface-elevation-300);
  padding: $base-spacing;
  padding-top: $xxl-spacing;
}

.container {
  flex-grow: 1;
  padding: 0 $containerPadSpace;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.headWrap {
  width: 100%;
  margin: $small-spacing -0.15em;

  @include mobileHeight {
    margin-top: -1 * $xs-spacing;
    margin-bottom: 0;
  }

  > p:not(:last-of-type) {
    margin-bottom: $xs-spacing;
  }
}

.contentWrap {
  width: 100%;
  flex: 1;

  @include mobileHeight {
    margin-bottom: $xxl-spacing;
  }
}

.submitWrap {
  width: 100%;
  max-width: $content-width-max;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index: 1;
}
