@import "../../../scss/base/variables";

.modal {
  z-index: $z-3;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(48, 46, 53, 0.9);
  box-shadow: $large-space-shadow;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 500px; // so it doesn't look dumb outside of iframe, mostly arbitrary
  margin-left: auto; // fixes centering from max-width constraint
  margin-right: auto; // fixes centering from max-width constraint
  padding: $base-spacing;
}

.close {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background-color: inherit;
}
