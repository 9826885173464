@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.root {
  user-select: none;
}

.amount {
  &-sm {
    filter: blur(3.5px);
  }
  &-md {
    filter: blur(5px);
  }
  &-lg {
    filter: blur(10px);
  }
}
