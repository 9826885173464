@mixin small-fact-base {
  .number {
    font-size: $small-number-font-size;
  }

  .explain {
    font-size: $small-font-size;
  }

  .sub-explain {
    font-size: $xs-font-size;
  }

  .explain,
  .sub-explain {
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
  }
}

.fact {
  margin-bottom: $xxl-spacing;

  &:last-of-type {
    margin-bottom: 0;
  }

  .chart-container {
    margin-bottom: $base-spacing;
  }
}

.fact h2,
.fact h3 {
  margin-bottom: $xs-spacing;
}

.fact-action {
  font-size: $small-font-size;
  margin-top: $base-spacing;
  position: relative;
  z-index: $base-z-index + 1; // ensure it's above the tip arrows
}

.fact-action--button {
  font-size: 1em;
}

.fact--small {
  @include small-fact-base;
}
