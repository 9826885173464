@import '../../../scss/base/variables';

// Can prop-itize:
$gaugeWidth: 1em;
$legendHeight: $large-spacing;

.meter {
  position: relative;
  width: 100%;

  .gauge {
    position: relative;
    width: 100%;
    overflow: hidden;

    .overflow {
      position: relative;
      display: block;
      padding-bottom: 50%;
      width: 100%;
      border-radius: 100%;

      &:before,
      &:after {
        content: close-quote;
        position: absolute;
        border-radius: 100%;
      }

      &:before {
        top: 0;
        right: 0;
        left: 0;
        bottom: -100%;
        background: linear-gradient(to right, var(--brand-secondary-500) 0%, var(--brand-primary-accent-500) 50%, var(--brand-danger-500) 100%), linear-gradient(to left, var(--brand-danger-500) 0%, var(--brand-primary-accent-500) 50%, var(--brand-secondary-500) 100%);
      }

      &:after {
        top: $gaugeWidth;
        right: $gaugeWidth;
        left: $gaugeWidth;
        bottom: calc(-100% + #{$gaugeWidth});
        background: var(--brand-surface-elevation-300);
      }
    }

    .lightOverflow {
      &:before {
        top: 0;
        right: 0;
        left: 0;
        bottom: -100%;
        background: linear-gradient(to right, var(--brand-secondary-300) 0%, var(--brand-primary-accent-200) 50%, var(--brand-danger-300) 100%), linear-gradient(to left, var(--brand-danger-300) 0%, var(--brand-primary-accent-200) 50%, var(--brand-secondary-300) 100%);
      }
    }
  }

  .dial {
    position: absolute;
    height: $gaugeWidth;
    width: calc(50% + #{0.25*$gaugeWidth});
    left: -0.25*$gaugeWidth;
    bottom: calc(#{$legendHeight} - #{0.5*$gaugeWidth});
    z-index: 2;
    transform: rotate(0deg);
    transform-origin: right;

    .arm {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0.5*$gaugeWidth;
      background: var(--brand-neutral-cool-900);

      // IE is dumb so give it a stupid thin line unless clip-path is supported
      height: 2px;
      margin: calc(#{0.5*$gaugeWidth} - 1px) -0.5*$gaugeWidth 0 0;

      // IE does not support display: grid
      // could be more specific here using clip-path, but tricky with an attribute that takes a function (potentially prefixed)
      @supports (display: grid) {
        height: $gaugeWidth;
        margin: 0;
        clip-path: polygon(0 50%, calc(100% + #{0.5*$gaugeWidth}) 0, calc(100% + #{$gaugeWidth}) 0, calc(100% + #{$gaugeWidth}) 100%, calc(100% + #{0.5*$gaugeWidth}) 100%);

        &:before {
          content: close-quote;
          position: absolute;
          display: block;
          top: 0;
          right: -$gaugeWidth;
          width: $gaugeWidth;
          height: $gaugeWidth;
          border-top-right-radius: 100%;
          border-bottom-right-radius: 100%;
          background: var(--brand-neutral-cool-900);
        }
      }
    }

    .grommet {
      position: absolute;
      display: block;
      width: 0.5*$gaugeWidth;
      height: 0.5*$gaugeWidth;
      top: 0.25*$gaugeWidth;
      right: -0.25*$gaugeWidth;
      border-radius: 100%;
      background: var(--brand-neutral-cool-400);

      &:before,
      &:after {
        content: close-quote;
        position: absolute;
        border-radius: 100%;
      }

      $outerWidth: $gaugeWidth;
      &:before {
        width: 0;
        height: 0;
        top: calc(50% - #{$outerWidth});
        left: calc(50% - #{$outerWidth});
        border: $outerWidth solid var(--brand-neutral-cool-400);
        border-radius: 100%;
        z-index: -1;
      }

      $innerWidth: 1px;
      &:after {
        width: 0;
        height: 0;
        top: calc(50% - #{$innerWidth});
        left: calc(50% - #{$innerWidth});
        border: $innerWidth solid var(--brand-neutral-cool-900);
        border-radius: 100%;
        z-index: 1;
      }
    }
  }

  .legend {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: $legendHeight;

    .low,
    .high {
      position: relative;
      display: block;
      margin-top: 0.5*$legendHeight;
      text-transform: uppercase;
      font-size: $xs-font-size;
      font-weight: $weight-semibold;
      letter-spacing: 1px;
      width: 6em;
    }

    .low {
      // Make it work
      color: var(--brand-secondary-500);
      margin-left: -2.5em;

      @media (max-width: 340px) {
        margin-left: unset;
      }
    }

    .high {
      // Make it work
      color: var(--brand-danger-500);
      margin-right: -2.5em;

      @media (max-width: 340px) {
        margin-right: unset;
      }
    }

    &:before,
    &:after {
      content: close-quote;
      position: absolute;
      border-radius: 100%;
      display: block;
      height: $gaugeWidth;
      width: $gaugeWidth;
      top: -0.5*$gaugeWidth;
    }

    &:before {
      left: 0;
      background: var(--brand-secondary-500);
    }
    &:after {
      right: 0;
      background: var(--brand-danger-500);
    }
  }

  .lightLegend {
    .low {
      color: var(--brand-secondary-300);
    }

    .high {
      color: var(--brand-danger-300);
    }

    &:before {
      background: var(--brand-secondary-300);
    }
    &:after {
      background: var(--brand-danger-300);
    }
  } 
}
