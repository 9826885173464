.subheader {
  position: relative;
  height: auto;
  min-height: auto;
  border-top: 1px solid var(--brand-surface-elevation-50);

  &:after,
  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 40px;
    z-index: 1;
    top: 0;
  }
}
