$loader-size: 30px;
$border-width: 2px;
$svg-height: 17px;
$border-outline-color: var(--brand-primary-100);
$border-loading-color: var(--brand-primary-500);

.container {
  position: relative;
}

.svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(50%);

  & path {
    fill: var(--brand-neutral-warm-700);
  }
}

.loader {
  border: $border-width solid $border-outline-color;
  border-top: $border-width solid $border-loading-color;
  border-radius: 50%;
  height: $loader-size;
  width: $loader-size;
  animation: spin 1.5s ease-in infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
