@import '../../../scss/base/variables';

.title {
  margin-left: 50px;
}

.notSelected {
  display: flex;
  transform: rotate(180deg);
  margin-left: $xs-spacing;
  margin-right: $xs-spacing;
}

.selected {
  display: flex;
  transform: rotate(0deg);
  margin-left: $xs-spacing;
  margin-right: $xs-spacing;
}

.dropdownSelectButton {
  background-color: transparent;
  padding: $small-spacing;
  position: relative;
  color: var(--brand-secondary-500);
  outline: none;
  border-radius: 0.25em 0 0 0.25em;

  span {
    margin-left: $xxs-spacing;
    margin-right: $xxs-spacing;
  }

  &:focus {
    outline: 0.125em solid var(--brand-neutral-cool-900);
  }
}

.dropdownSelectMenu {
  border-radius: 0.25em;
  padding: $xs-spacing;
}
