.dashboard {
  margin-top: 2rem;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;

  > * {
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  --digest-module-scroll-margin-top: calc(
    var(--top-nav-bar-height, 0px) + var(--banner-height, 0px) + 1.5rem
  );
}
