@use "sass:math";

@function peekMargin($size) {
  @return $size * 0.5;
}

@function peekPadding($size) {
  @return math.div($size * 2, 3);
}

@mixin peekTopMargin($size) {
  margin-top: peekMargin($size);
}

@mixin peekLeftMargin($size) {
  margin-left: peekMargin($size);
}

@mixin peekRightMargin($size) {
  margin-right: peekMargin($size);
}

@mixin peekHMargin($size) {
  @include peekLeftMargin($size);
  @include peekRightMargin($size);
}

@mixin peekInlineLeftPadding($size) {
  padding-left: peekPadding($size);
}

@mixin peekInlineRightPadding($size) {
  padding-right: peekPadding($size);
}

@mixin peekInlineHPadding($size) {
  @include peekInlineLeftPadding($size);
  @include peekInlineRightPadding($size);
}

@mixin peekTopContainer($size) {
  position: relative;
  @include peekTopMargin($size);
}

@mixin peekLeftContainer($size) {
  position: relative;
  @include peekLeftMargin($size);
}

@mixin peekRightContainer($size) {
  position: relative;
  @include peekRightMargin($size);
}

@mixin peekLeftInlineContainer($size) {
  position: relative;
  @include peekInlineLeftPadding($size);
  @include peekLeftMargin($size);
}

@mixin peekRightInlineContainer($size) {
  position: relative;
  @include peekInlineRightPadding($size);
  @include peekRightMargin($size);
}

@mixin peekTop {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
}

@mixin peekRight {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  line-height: 0;
}

@mixin peekLeft {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
}
