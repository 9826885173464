@import '../../../scss/base/variables';

.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  &:before {
    content: '•';
    padding-right: $small-spacing;
  }
}
