@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

.root {
  margin-bottom: $xs-spacing;
}

.label {
  margin: 0;
}

.hide {
  @include hideLabel;
}
