@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$teaser-margin: $small-spacing;

.callout {
  position: fixed;
  width: calc(100vw - 2*#{$teaser-margin});
  max-width: 375px;
  height: auto;
  bottom: $teaser-margin;
  background-color: var(--brand-surface-elevation-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $small-spacing;
  border-radius: $medium-border-radius;
  backdrop-filter: blur(100px);
  @include themedMixins.neutralDown-500;
  opacity: 1;

  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 900px) {
    &.position {
      &-left {
        left: $teaser-margin;
        transform: none;
      }

      &-right {
        left: unset;
        right: $teaser-margin;
        transform: none;
      }
    }
  }
}

.closeButton {
  align-self: flex-end;
  font-size: $base-font-size;
}

.fadeOut {
  opacity: 0;
  bottom: -100%;
  transition: bottom 2s $base-easing,
              opacity .5s $base-easing;
}
