.card {
  @include card-base;
  margin-bottom: $base-spacing;
  transition: all $base-duration $base-easing;
  width: 100%;
  position: relative;
  > h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }

  &.half-card {
    margin-left: auto;
    margin-right: auto;
    min-width: $content-width-max * 0.5;
    width: 50%;
  }
}

.slide:not(.active) > .card {
  opacity: 0.2;

  @media (min-width: $medium-screen) {
    opacity: 0.3;
  }

  .number,
  a,
  #{$all-text-inputs},
  select,
  .checkbox-inline,
  .radio-inline,
  label:before,
  span,
  p {
    color: var(--brand-hb-white) !important;
  }

  .bar,
  button,
  .bar-chart div {
    background: tint($card-background-color, 10%);
  }

  // block direct clicks on elements (accidental button clicks, etc.)
  &:after {
    @include position(absolute, 0 0 0 0);
    content: '';
    cursor: pointer;
    z-index: 1;
  }
}

.content-main .card {
  background-image: url(#{$assets_base}img/bg-card.png);
}

.card-stats {
  p {
    text-align: left;
  }

  .stat-val {
    color: var(--brand-success-700);
  }
}

.card-pro {
  $photo-pull-top: 2.25em;
  @include card-padding;
  background: $dark-blue;
  flex: 1;
  // margin-top: $photo-pull-top;

  &.missing-photo {
    padding-top: 4.5em;
  }

  &:nth-of-type(2n) {
    background: $darker-blue;
  }

  > .explain {
    margin-bottom: $xs-spacing;
  }

  figure {
    @include spinning-border;
    margin: (-$card-pad-vertical - $photo-pull-top) auto $base-spacing;

    .photo {
      @include square(em(72));
      @include circle-photo;
      margin: 0 auto;
    }
  }

  .list-actions {
    @include position(absolute, $photo-pull-top*0.5 $base-spacing auto auto);

    a {
      color: $brand-xs-quiet;
    }
  }

  .list-credentials {
    color: $brand-xs-quiet;
    font-size: 14px;
    margin: $small-spacing 0 0;

    li {
      margin-bottom: $small-spacing;
    }

    &--tight {
      li {
        margin-bottom: 0;
      }

      > li:last-of-type {
        margin-bottom: $large-spacing;
      }
    }
  }
}

.card--small {
  @media (min-width: $medium-screen) {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
}

.card--standalone {
  background: none;
  box-shadow: none;
}

.card-section + .card--standalone {
  padding-top: 0;
}

.step-through {
  position: relative;
  transition: height $base-duration $base-easing;

  > li {
    @include position(absolute, 0 0 auto 0);
  }
}

.cards-tiled {
  box-shadow: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  .card {
    margin-left: $xs-spacing;
    margin-bottom: $section-spacing;
    margin-right: $xs-spacing;
    max-width: $content-width-max * 0.5;
  }

  &.cards-tiled--together {
    box-shadow: $card-shadow;

    .card {
      box-shadow: none;
      margin: 0;
    }
  }

  &.cards-tiled--half-width {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $medium-screen) {
      width: 50%;
    }

    .card {
      box-shadow: $card-shadow;
    }
  }
}

.card-section {
  margin-bottom: $section-spacing;
}

.card-inner-section {
  margin: $base-spacing 0;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.card .slider {
  margin: 0 auto;
  width: 75%;
}

.card .col .slider {
  width: 90%;
}
