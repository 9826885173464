@import '../../../scss/base/variables';

.container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.actionBar {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $small-spacing;
}

.opaqueBg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--hb-colors-card-bg-layer-1);
}

.heading {
  flex: 2 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left,
.right {
  & > :not(:last-child) {
    margin-right: calc($medium-spacing / 2);
  }
}

.right {
  display: flex;
  flex-direction: row;
}
