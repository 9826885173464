@import '../../../scss/base/variables';

.icon {
  display: inline-block;
  font: normal normal normal 14px/1 HomebotWeb;
  font-size: inherit;
  font-weight: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  transition: transform 0.3s;

  &.flipped {
    transform: scaleX(-1);
  }
}

.icon__primary {
  color: var(--brand-primary-500);

  &-50 {
    color: var(--brand-primary-50);
  }
  &-100 {
    color: var(--brand-primary-100);
  }
  &-200 {
    color: var(--brand-primary-200);
  }
  &-300 {
    color: var(--brand-primary-300);
  }
  &-400 {
    color: var(--brand-primary-400);
  }
  &-500 {
    color: var(--brand-primary-500);
  }
  &-600 {
    color: var(--brand-primary-600);
  }
  &-700 {
    color: var(--brand-primary-700);
  }
  &-800 {
    color: var(--brand-primary-800);
  }
  &-900 {
    color: var(--brand-primary-900);
  }
}

.icon__secondary {
  color: var(--brand-secondary-500);

  &-50 {
    color: var(--brand-secondary-50);
  }
  &-100 {
    color: var(--brand-secondary-100);
  }
  &-200 {
    color: var(--brand-secondary-200);
  }
  &-300 {
    color: var(--brand-secondary-300);
  }
  &-400 {
    color: var(--brand-secondary-400);
  }
  &-500 {
    color: var(--brand-secondary-500);
  }
  &-600 {
    color: var(--brand-secondary-600);
  }
  &-700 {
    color: var(--brand-secondary-700);
  }
  &-800 {
    color: var(--brand-secondary-800);
  }
  &-900 {
    color: var(--brand-secondary-900);
  }
}

.icon__success {
  color: var(--brand-success-700);

  &-50 {
    color: var(--brand-success-50);
  }
  &-100 {
    color: var(--brand-success-100);
  }
  &-200 {
    color: var(--brand-success-200);
  }
  &-300 {
    color: var(--brand-success-300);
  }
  &-400 {
    color: var(--brand-success-400);
  }
  &-500 {
    color: var(--brand-success-500);
  }
  &-600 {
    color: var(--brand-success-600);
  }
  &-700 {
    color: var(--brand-success-700);
  }
  &-800 {
    color: var(--brand-success-800);
  }
  &-900 {
    color: var(--brand-success-900);
  }
}

.icon__warning {
  color: var(--brand-warning-500);

  &-50 {
    color: var(--brand-warning-50);
  }
  &-100 {
    color: var(--brand-warning-100);
  }
  &-200 {
    color: var(--brand-warning-200);
  }
  &-300 {
    color: var(--brand-warning-300);
  }
  &-400 {
    color: var(--brand-warning-400);
  }
  &-500 {
    color: var(--brand-warning-500);
  }
  &-600 {
    color: var(--brand-warning-600);
  }
  &-700 {
    color: var(--brand-warning-700);
  }
  &-800 {
    color: var(--brand-warning-800);
  }
  &-900 {
    color: var(--brand-warning-900);
  }
}

.icon__danger {
  color: var(--brand-danger-500);

  &-50 {
    color: var(--brand-danger-50);
  }
  &-100 {
    color: var(--brand-danger-100);
  }
  &-200 {
    color: var(--brand-danger-200);
  }
  &-300 {
    color: var(--brand-danger-300);
  }
  &-400 {
    color: var(--brand-danger-400);
  }
  &-500 {
    color: var(--brand-danger-500);
  }
  &-600 {
    color: var(--brand-danger-600);
  }
  &-700 {
    color: var(--brand-danger-700);
  }
  &-800 {
    color: var(--brand-danger-800);
  }
  &-900 {
    color: var(--brand-danger-900);
  }
}

.icon__neutralCool {
  color: var(--brand-neutral-cool-500);

  &-50 {
    color: var(--brand-neutral-cool-50);
  }
  &-100 {
    color: var(--brand-neutral-cool-100);
  }
  &-200 {
    color: var(--brand-neutral-cool-200);
  }
  &-300 {
    color: var(--brand-neutral-cool-300);
  }
  &-400 {
    color: var(--brand-neutral-cool-400);
  }
  &-500 {
    color: var(--brand-neutral-cool-500);
  }
  &-600 {
    color: var(--brand-neutral-cool-600);
  }
  &-700 {
    color: var(--brand-neutral-cool-700);
  }
  &-800 {
    color: var(--brand-neutral-cool-800);
  }
  &-900 {
    color: var(--brand-neutral-cool-900);
  }
}

.icon__neutralWarm {
  color: var(--brand-neutral-warm-500);

  &-50 {
    color: var(--brand-neutral-warm-50);
  }
  &-100 {
    color: var(--brand-neutral-warm-100);
  }
  &-200 {
    color: var(--brand-neutral-warm-200);
  }
  &-300 {
    color: var(--brand-neutral-warm-300);
  }
  &-400 {
    color: var(--brand-neutral-warm-400);
  }
  &-500 {
    color: var(--brand-neutral-warm-500);
  }
  &-600 {
    color: var(--brand-neutral-warm-600);
  }
  &-700 {
    color: var(--brand-neutral-warm-700);
  }
  &-800 {
    color: var(--brand-neutral-warm-800);
  }
  &-900 {
    color: var(--brand-neutral-warm-900);
  }
}

.icon__white {
  color: var(--brand-hb-white);
}
