.company-branding {
  @include nav-dropdown;

  @media (min-width: $medium-screen) {
    margin-left: -50px;
    width: 325px;
  }
}

.company-branding--info {
  padding: $base-spacing;
}

.company-branding--details {
  margin: 0;
}
