fieldset {
  background: none;
  border: none;
}

label,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

label {
  cursor: pointer;
  margin-bottom: $xs-spacing;

  &.label--error {
    text-align: left;
    color: $brand-danger;
  }
}

textarea {
  color: $white;
  font-size: 18px;
  height: 6em;
  resize: vertical;

  &::placeholder {
    font-weight: $weight-regular;
  }

  &.textarea--disclaimer {
    height: 10em;
    font-size: 14px;
  }
}

input[type='search'] {
  @include appearance(none);
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
  margin-right: $small-spacing * 0.5;
}

input[type='file'] {
  padding-bottom: $small-spacing;
  width: 100%;
}

.form .button-group:last-child {
  margin-bottom: 0;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    @include button-base;
    font-size: 1em;
  }

  &:focus + label {
    background-color: var(--brand-secondary-500);
    outline: -webkit-focus-ring-color auto 5px;
  }
}

.radio,
.checkbox {
  background: $input-background-color;
  border-radius: $base-border-radius;
  display: inline-block;
  margin-right: $small-spacing;
  padding: $xs-spacing $small-spacing;
  transition: background-color $base-easing $base-duration,
    transform $base-easing $short-duration*0.5;

  &:hover {
    background: $input-background-color-focused;
  }

  &:active {
    transform: scale(0.97);
  }
}

.form-sub-explain {
  color: $brand-quiet;
  margin-top: $base-spacing;
  text-align: center;
}

.split-form {
  @include row();
  .col {
    @include span-columns(6);
    // background: darken($dark-purple, 1%);
    padding: 0 $base-spacing;
  }
}

.checkbox-inline,
.radio-inline {
  color: var(--brand-primary-600);
  display: inline-block;
  padding: $small-spacing $base-spacing $small-spacing 0;
}

.money-input,
.hash-input {
  position: relative;

  input {
    padding-left: 1.1em;
  }

  label::before {
    @include position(absolute, auto auto $input-pad-vert $small-spacing*0.5);
    color: var(--brand-primary-600);
    font-size: 24px;
  }

  &.money-input label::before {
    content: '$';
  }

  &.hash-input label::before {
    content: '#';
    color: $brand-quiet;
  }
}

.percent-input {
  position: relative;

  input {
    padding-right: 75%;
  }

  label::before {
    @include position(absolute, auto auto $input-pad-vert 4em);
    content: '%';
    color: var(--brand-primary-600);
    font-size: 24px;
  }
}

.form-sentence {
  font-size: 24px;
  font-weight: $weight-light;
  line-height: 2.4em;
  text-align: left !important;

  #{$all-text-inputs} {
    background: none;
    border-bottom: 1px solid var(--brand-neutral-cool-400);
    display: inline-block;
    font-size: 1em;
    font-weight: normal;
    padding: 0 0.2em;
    text-align: center;
    width: 130px;
  }

  .number {
    font-size: 1em;
  }

  .input-rate {
    width: 70px !important;
  }
}

.form-after {
  background: url(#{$assets_base}img/bg-dashed-line.svg) repeat-x 0 100%;
  background-position: top;
  margin-top: $large-spacing;
  padding-top: $large-spacing;
}

.form-save-msg {
  margin-bottom: $base-spacing;
}

.radio-button input[type='radio'] {
  display: none;
}

.form-group--inline {
  display: flex;

  input {
    flex: 1 1 auto;
    min-width: 0;
  }

  button {
    flex: 0 0 0;
  }

  .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.form__header {
  margin-bottom: $xl-spacing;
}

.form__title {
  @include h2;
  display: block;
  margin-bottom: $xs-spacing;
  margin-left: 0;
  text-align: left;
}

.form__sub-title {
  @include font-small;
  @include font-quiet;
  display: block;
  margin-bottom: $base-spacing;
  text-align: left;
}

.form-v2--has-progress-indicator .form__sub-title {
  max-width: 80%;
}

.form__fields {
  margin-bottom: $xl-spacing;

  @include form_fields_wrap;
}

.form__fields--no-margin {
  @include form_fields_wrap;
}

.form__fields--single-column {
  @media (min-width: $medium-screen) {
    display: block;
  }
}

.form__actions--stepped {
  display: flex;
  justify-content: flex-end;
}

.form--recurly {
  input {
    font-weight: $weight-regular;
  }
}
