$handle-size: 1em;
$handle-border-width: 2px;

.slider {
  padding-bottom: $handle-size * 3;
  position: relative;
}

.slider-container {
  padding: $small-spacing 0;
}

.slider-description {
  @include font-xs();
  @include font-quiet();
  margin-bottom: $xs-spacing;
}

.slider-main {
  display: inline-block;
  position: relative;
  top: 5px;
  width: 100%;
}

.slider-min,
.slider-max {
  @include font-xs();
  @include font-quiet();
  height: $handle-size;
  opacity: 1;
  position: absolute;
  top: $handle-size * 1.66;
  transition: opacity $base-duration $base-easing;
}

.slider-min {
  left: 0;
}

.slider-max {
  right: 0;
}

.slider-at-min .slider-min,
.slider-at-max .slider-max {
  opacity: 0;
}

.slider-tooltip {
  @include position(absolute, $handle-size auto auto auto);
  background: #292530;
  font-size: $handle-size;
  font-weight: $weight-semibold;
  padding: 2px $small-spacing;
  text-align: center;
  transform: translate(-40%, 0);
  transition: top $short-duration $base-easing;
}

.noUi-background {
  background: transparent;

  &:after {
    @include position(absolute, $handle-size*0.5 0 auto 0);
    background: $brand-quiet;
    border-radius: 2px;
    content: '';
    height: $handle-border-width;
  }
}

.noUi-target {
  border-radius: 4px;
  border: none;
  box-shadow: none;
}
.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #bbb;
}

/* Handles and cursors;
 */
.noUi-horizontal .noUi-handle {
  @include square($handle-size);
  background: $card-background-color;
  border: $handle-border-width solid var(--brand-primary-600);
  border-radius: 10em;
  box-shadow: 0 0 0 $handle-border-width * 1.5 $card-background-color;
  cursor: pointer;
  left: -0.5em;
  top: 1px;
  transition: border $short-duration $base-easing,
    transform $short-duration $base-easing,
    box-shadow $short-duration $base-easing;

  &:hover {
    transform: scale(1.1);
  }

  &.noUi-active {
    box-shadow: 0 0 3px 0
      transparentize(lighten($card-background-color, 80), 0.5);
    transform: scale(1.25);
  }
}

.touch .noUi-active .slider-tooltip {
  background: transparentize($card-background-color, 0.3);
  top: (-$handle-size * 2);
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  display: none;
}

.slider-solid {
  .slider-tooltip {
    @include position(relative, 0 0 0 0);
    transition: color $short-duration $base-easing,
      top $short-duration $base-easing;
    line-height: 1;
    width: auto;
  }

  /* Handles and cursors;
   */

  .noUi-background {
    background: transparent;

    &:after {
      top: $handle-size * 0.7;
    }
  }

  .slider-tooltip {
    font-size: 18px;
  }

  .noUi-horizontal .noUi-handle {
    @include size(3em, 2em);
    // background: $white;
    // border: none;
    border-radius: $base-border-radius;
    // color: $card-background-color;
    font-size: 18px;
    padding: 0.2em 0.4em;

    &.noUi-active {
      transform: scale(1.25);

      .slider-tooltip {
        color: $white;
        top: (-$handle-size * 1.2);
      }
    }
  }
}
