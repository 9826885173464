// Drop-shadows //

/* ======================================== */

@mixin neutralDown-100 {
  box-shadow: 0px 1px 1px var(--brand-hb-shadow-neutral-05);
}

@mixin neutralDown-200 {
  box-shadow: 0px 1px 2px var(--brand-hb-shadow-neutral-10),
    0px 1px 1px var(--brand-hb-shadow-neutral-06);
}

@mixin neutralDown-300 {
  box-shadow: 0px 3px 4px var(--brand-hb-shadow-neutral-08),
    0px 2px 2px var(--brand-hb-shadow-neutral-06);
}

@mixin neutralDown-400 {
  box-shadow: 0px 7px 8px var(--brand-hb-shadow-neutral-08),
    0px 2px 2px var(--brand-hb-shadow-neutral-08);
}

@mixin neutralDown-500 {
  box-shadow: 0px 8px 12px var(--brand-hb-shadow-neutral-10),
    0px 3px 3px var(--brand-hb-shadow-neutral-08);
}

@mixin neutralDown-600 {
  box-shadow: 0px 10px 12px var(--brand-hb-shadow-neutral-07),
    0px 4px 8px var(--brand-hb-shadow-neutral-07);
}

@mixin neutralDown-700 {
  box-shadow: 0px 11px 17px var(--brand-hb-shadow-neutral-08),
    0px 6px 6px var(--brand-hb-shadow-neutral-05);
}

@mixin neutralDown-800 {
  box-shadow: 0px 20px 18px var(--brand-hb-shadow-neutral-15);
}

@mixin neutralDown-900 {
  box-shadow: 0px 24px 24px var(--brand-hb-shadow-neutral-20);
}

/* ======================================== */

@mixin neutralUp-100 {
  box-shadow: 0px -1px 1px var(--brand-hb-shadow-neutral-05);
}

@mixin neutralUp-200 {
  box-shadow: 0px -1px 2px var(--brand-hb-shadow-neutral-10),
    0px -1px 1px var(--brand-hb-shadow-neutral-06);
}

@mixin neutralUp-300 {
  box-shadow: 0px -3px 4px var(--brand-hb-shadow-neutral-08),
    0px -2px 2px var(--brand-hb-shadow-neutral-06);
}

@mixin neutralUp-400 {
  box-shadow: 0px -7px 8px var(--brand-hb-shadow-neutral-08),
    0px -2px 2px var(--brand-hb-shadow-neutral-08);
}

@mixin neutralUp-500 {
  box-shadow: 0px -8px 12px var(--brand-hb-shadow-neutral-10),
    0px -3px 3px var(--brand-hb-shadow-neutral-08);
}

@mixin neutralUp-600 {
  box-shadow: 0px -10px 12px var(--brand-hb-shadow-neutral-07),
    0px -4px 8px var(--brand-hb-shadow-neutral-07);
}

@mixin neutralUp-700 {
  box-shadow: 0px -11px 17px var(--brand-hb-shadow-neutral-08),
    0px -6px 6px var(--brand-hb-shadow-neutral-05);
}

@mixin neutralUp-800 {
  box-shadow: 0px -20px 18px var(--brand-hb-shadow-neutral-15);
}

@mixin neutralUp-900 {
  box-shadow: 0px -24px 24px var(--brand-hb-shadow-neutral-20);
}
