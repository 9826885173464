@use '../../../../scss/themes/mixins' as themedMixins;
@import '../../../../scss/base/variables';
@import '../../../../scss/buyers_mixins';
@import '../../Panel/variables.module.scss';

$top-decoration-height: 36px;

.topDecoration {
  @include escapePanelOverlayPaddingH;

  height: $top-decoration-height;
  background: var(--brand-primary-custom, var(--brand-primary-600));
  border-top-left-radius: $large-border-radius;
  border-top-right-radius: $large-border-radius;
}

.companyAvatar {
  @include square(96px);

  position: absolute;
  transform: translate(-50%, calc(-50% + ($top-decoration-height / 2)));
  @include themedMixins.neutralDown-300;
}
