.notification {
  @include reveal-vert(5em);
  background-color: var(--brand-warning-600);
  margin-bottom: $base-spacing;
  padding: $small-spacing $base-spacing $small-spacing;
  position: relative;

  .explain,
  .action {
    font-size: em(18);
    margin-bottom: $xs-spacing;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .icon {
    $size: 28px;
    @include position(relative, 0.05em 0 0 0);
    color: var(--brand-warning-500);
    font-size: 1em;
    margin-right: 0.25em;
  }

  .button {
    margin-left: $xs-spacing;
  }
}

.card .notification,
.modal .notification {
  @include stretch-to-card-edge;

  .icon {
    @include position(relative, 0.05em 0 0 0);
    font-size: 1em;
    margin-right: 0.25em;
  }
}

.list-tabled .notification {
  font-size: em(13);
  padding: $xs-spacing $small-spacing;
}

.page-notification {
  @include position(fixed, 0 0 auto 0);
  @include reveal-vert;
  background: transparent;
  box-shadow: $large-space-shadow;
  z-index: $z-page-notification;

  .explain {
    background: var(--brand-primary-600);
    padding: $base-spacing $base-spacing;
    overflow: visible;
    text-align: center;
    transition: all $base-easing $base-duration;
    color: var(--brand-hb-white);
    font-size: em(18);
    margin-bottom: 0;
  }
}
