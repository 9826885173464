@import "../../../scss/base/variables";

.container {
  display: flex;
  flex-direction: column;
  display: flex;
  flex: 1;
}

.contentBody {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.steps {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $base-spacing;
  margin-bottom: $base-spacing;
}

.continueBtn {
  margin-bottom: $base-spacing;
}

.disclaimerContainer {
  margin-top: $small-spacing;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.disclaimer {
  font-weight: $weight-bold;
  border: none;
  color: $heather;
  font-size: $base-font-size;
  background-color: $purple-02;

  &:hover,
  &:focus,
  &:active {
    color: lighten($heather, 15%);
  }
}
