@import '../../../scss/base/variables';

.container {
  display: inline-block;
  border: solid 1px;
  border-radius: 2px;
  font-size: 13px;
  padding: 3px 8px;
  text-align: center;
  margin-bottom: 0;
}

.type-danger {
  border-color: var(--brand-danger-400);
  color: var(--brand-danger-600);
}
