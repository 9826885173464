@forward 'src/scss/themes/mappings/components/text';
@import '../../../scss/base/variables';

.text {
  font-family: $base-font-family;
  margin: 0;
  padding: 0;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.size {
  &-smallest {
    font-size: $smallest-font-size;
  }

  &-tiny {
    font-size: $tiny-font-size;
  }
  &-xxs {
    font-size: $xxs-font-size;
  }
  &-xs {
    font-size: $xs-font-size;
  }
  &-s {
    font-size: $small-font-size;
  }
  &-m {
    font-size: $base-font-size;
  }
  &-l {
    font-size: $l-font-size;
  }
  &-xl {
    font-size: $xl-font-size;
    letter-spacing: -0.02em;
  }
  &-xxl {
    font-size: $xxl-font-size;
    letter-spacing: -0.02em;
  }
  &-xxxl {
    font-size: $xxxl-font-size;
    letter-spacing: -0.02em;
  }
}

.weight {
  &-light {
    font-weight: $weight-light;

    &.size {
      &-tiny {
        font-weight: $weight-regular;
      }
      &-xxs {
        font-weight: $weight-regular;
      }
      &-xs {
        font-weight: $weight-regular;
      }
      &-s {
        font-weight: $weight-regular;
      }
    }
  }
  &-regular {
    font-weight: $weight-regular;
  }
  &-semibold {
    font-weight: $weight-semibold;
  }
  &-bold {
    font-weight: $weight-bold;
  }
}

.letter-spacing {
  &-10 {
    letter-spacing: 0.1em;
  }
}

.align {
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
  }
  &-justify {
    text-align: justify;
  }
  &-start {
    text-align: start;
  }
  &-end {
    text-align: end;
  }
}

.paragraph {
  margin-bottom: $small-spacing;
}

.nowrap {
  white-space: nowrap;
}

.transform {
  &-capitalize {
    text-transform: capitalize;
  }
  &-lowercase {
    text-transform: lowercase;
  }
  &-uppercase {
    text-transform: uppercase;
  }
}

.upperspaced {
  text-transform: uppercase;
  letter-spacing: 1px;
}

