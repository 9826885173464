@use "src/scss/themes/mixins" as themedMixins;
@import "./variables.module.scss";

.button {
  background-color: var(--brand-neutral-cool-50);
  border: none;
  border-radius: $button-border-radius;
  color: var(--brand-secondary-600);
  cursor: pointer;
  font-family: $button-font-family;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1;
  padding: $button-padding;
  text-decoration: none;
  transition: $button-transition;
  user-select: none;
  white-space: nowrap;
  vertical-align: baseline;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:active {
    animation: $button-animation-active;
    background-color: var(--brand-secondary-400);
    @include themedMixins.neutralDown-200;
    color: var(--brand-hb-white);
    outline: $button-outline-active;
  }

  &:focus {
    animation: $button-animation-focus;
    background-color: var(--brand-neutral-cool-50);
    @include themedMixins.neutralDown-200;
    color: var(--brand-secondary-600);
    outline: $button-outline-focus;
  }

  &:hover {
    animation: $button-animation-hover;
    background-color: var(--brand-secondary-400);
    @include themedMixins.neutralDown-200;
    color: var(--brand-hb-white);
    outline: $button-outline-hover;
  }

  &:disabled {
    color: var(--brand-neutral-cool-900);
    background-color: var(--brand-neutral-cool-50);
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      animation: none;
      cursor: not-allowed;
      color: var(--brand-hb-white);
    }
  }

  &.wide {
    display: flex;
    width: 70%;
  }

  &.fullWidth {
    display: flex;
    width: 100%;
  }

  &.minimal {
    animation: none;
    background: none;
    padding: 0.25rem;
    width: auto;
    height: auto;

    &:active,
    &:focus,
    &:hover {
      animation: none;
      color: var(--brand-secondary-500);
      box-shadow: none;
    }

    .iconEnd {
      margin-left: 0.25em;
    }

    .iconStart {
      margin-right: 0.25em;
    }

    &.iconOnly {
      .iconEnd,
      .iconStart {
        margin: 0;
      }
    }
  }

  &.danger {
    color: var(--brand-danger-500);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--brand-danger-300);
      color: var(--brand-hb-white);
    }

    &.minimal {
      color: var(--brand-danger-500);

      &:hover,
      &:focus,
      &:active {
        background: none;
        color: var(--brand-danger-600);
      }

      &:disabled {
        color: var(--brand-neutral-cool-600);
        cursor: not-allowed;
      }
    }
  }

  &.primary {
    background-color: var(--brand-secondary-400);
    color: var(--brand-hb-white);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--brand-secondary-300);
    }

    &.danger {
      background-color: var(--brand-danger-500);

      &:hover,
      &:focus,
      &:active {
        background-color: var(--brand-danger-400);
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.secondary {
    background-color: var(--brand-primary-500);
    color: var(--brand-hb-white);
    border: 1px solid var(--brand-primary-border-200);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--brand-primary-400);
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.primaryCustom {
    background-color: var(--brand-primary-custom, var(--brand-primary-500));
    color: var(--brand-hb-white);

    &:hover,
    &:focus,
    &:active {
      opacity: 0.9;
    }
  }

  &.loading {
    animation: $button-animation-loading;
    animation-delay: $button-animation-delay-loading;
    border: $button-border-loading;
    border-radius: $button-border-radius-loading;
    border-color: $button-border-color-loading;
    width: $icon-button-size;
    height: $icon-button-size;
    transition: $button-transition;
    outline: none;

    &:disabled {
      opacity: 0.5;
      cursor: wait;

      &:hover,
      &:focus {
        animation: $button-animation-loading;
        animation-delay: $button-animation-delay-loading;
      }
    }
  }
}

.iconOnly {
  height: $icon-button-size;
  width: $icon-button-size;
  padding: 0;

  .iconEnd,
  .iconStart {
    margin: 0;
  }

  &.compact {
    width: $icon-button-size-compact;
    height: $icon-button-size-compact;
    padding: $icon-button-padding-compact;
  }

  &:active,
  &:focus,
  &:hover {
    animation: $icon-button-animation;
  }
}

.compact {
  padding: $button-padding-compact;
}

.iconEnd {
  margin-left: $button-icon-margin;
}

.iconStart {
  margin-right: $button-icon-margin;
}
