@use '../../../../../scss/themes/mixins' as themedMixins;
@import '../../../../../scss/base/variables';

.container {
  border-radius: 16px;
  background: var(--brand-primary-50);
  @include themedMixins.neutralDown-900;
}

.imageBackground {
  height: 220px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  // fallback for browsers that don't support image-set
  background-image: url('#{$assets_base}img/16x9-mobile-app-outside-natural@1x.jpg');
  background-image: -webkit-image-set(
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@1x.jpg') 1x,
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@2x.jpg') 2x);
  background-image: image-set(
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@1x.jpg') 1x,
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@2x.jpg') 2x);
  background-size: cover;
  background-position: center center;
  mix-blend-mode: luminosity;
}

.contents {
  border-left: 1px solid var(--brand-primary-border-50);
  border-right: 1px solid var(--brand-primary-border-50);
  border-bottom: 1px solid var(--brand-primary-border-50);
  padding-left: $large-spacing;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.header {
  color: var(--brand-primary-900);
  padding-bottom: 5px;
  text-align: left;
}

.description {
  color: var(--brand-primary-800);
  padding-bottom: 24px;
  line-height: 21px;
}

.buttonLinkText {
  margin-right: 0.75em;
  font-size: 14px;
  font-weight: $weight-semibold;
}