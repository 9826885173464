@import "../../../scss/base/variables";

.notFoundStep {
  margin-top: $base-spacing;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.headline {
  margin-bottom: $xs-spacing;
}

.emailAddress {
  font-family: $monospace-font-family;
}

.helper {
  margin-top: $small-spacing;
}
