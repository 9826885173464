@import '../../../scss/base/variables';

.container {
  margin-top: 1rem;
  text-align: center;
  border-radius: 0 0 $medium-border-radius $medium-border-radius;

  .button {
    font-size: $small-font-size;
    color: var(--brand-neutral-cool-700);
  }
}
