@use "sass:math";

.form-group {
  margin-bottom: $large-spacing;
}

.form-group-columns {
  .col {
    background-color: $input-background-color;
    margin-top: $xs-spacing;
    padding-right: $small-spacing;
    transition: background-color math.div($base-duration, 1.5) $base-easing;

    label {
      color: $light-gray;
      font-size: 11px;
      padding: 1px 5px 0;
      text-transform: uppercase;
    }

    input {
      background: none;
    }

    &:hover,
    &.focused {
      background-color: $input-background-color-focused;
      animation: pop $base-duration;
    }
  }

  input {
    padding-top: 0;
    padding-right: 0;
  }

  select {
    margin-left: 5%;
    width: 95%;
  }

  @media (min-width: $medium-screen) {
    & {
      display: flex;
      flex-wrap: nowrap;
      max-width: 100%;
    }

    .col {
      @include fixed-space-between($xs-spacing);
      flex: 1 1 0%;
      margin-top: 0;
    }

    input {
      margin-bottom: 0;
    }
  }
}

.form-group--address {
  @media (min-width: $medium-screen) {
    .col:first-child {
      flex-grow: 3;
    }
  }
}

.form-group--cc-details {
  .form-group-columns {
    display: flex;
    flex-wrap: nowrap;
  }

  .col {
    flex: 0 0 auto;
    margin-right: $xs-spacing;
  }

  .col:last-of-type {
    margin-left: auto;
    margin-right: 0;
  }

  .col,
  .recurly-hosted-field {
    width: 100px;
  }
}

.form-group__cc-details--subscription {
  display: flex;
}

.form-group__expiry {
  margin-right: 10px;
}

.form-group-toggles--long {
  label.radio,
  label.checkbox {
    display: block;
  }
}

.form-group--two-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-group--state {
  min-width: 1.5em;
}

.form-group-explain {
  color: $brand-quiet;
  margin-top: (-$large-spacing) + $xs-spacing;
  margin-bottom: $large-spacing;
}

.form-group--no-label {
  padding-top: 0;

  @media (min-width: $medium-screen) {
    padding-top: $base-spacing * 1.1;
  }
}
