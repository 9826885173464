@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.listItem {
  list-style-type: none;
  margin-left: 2em;
  margin-right: 2em;

  @include smallScreen {
    margin: auto
  }
}

.dividerContainer {
  list-style-type: none;

  hr {
    margin: $xxs-spacing 0;
  }
}

.buttonText {
  background-color: inherit;
  color: var(--brand-neutral-cool-900);
  white-space: nowrap;
  border: none;
  width: 100%;
  text-align: left;
  padding: $xxs-spacing;
  cursor: pointer;
  border-radius: 0.25em;
  border: 1px solid transparent;

  &:hover {
    background-color: var(--brand-secondary-500);
    color: var(--brand-hb-white);
  }

  &:active {
    filter: brightness(75%);
  }
}

.activeItem {
  color: var(--brand-secondary-500);
  white-space: nowrap;
  border: none;
  width: 100%;
  text-align: left;
  padding: $xxs-spacing;

  &:hover {
    cursor: default;
    background-color: transparent;
    color: var(--brand-neutral-cool-300);
  }
}
