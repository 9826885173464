.nav-global {
  $action-color: #477dca !default;
  $large-screen: em(860) !default;
  $navigation-padding: 1em;
  $navigation-background: $shade-04;
  $navigation-color: $brand-xs-quiet;
  $navigation-color-hover: white;
  $navigation-height: $global-nav-height;
  $navigation-active-link-color: transparentize(white, 0.5);
  $horizontal-bar-mode: 0; //$large-screen;

  background-color: $navigation-background;
  border-bottom: 1px solid darken($navigation-background, 10);
  min-height: $navigation-height;
  width: 100%;

  .navigation-wrapper {
    @include clearfix;
    @include outer-container;
    position: relative;
  }

  .logo {
    color: $navigation-color-hover;
    float: left;
    font-size: em(36);
    height: $navigation-height;
    line-height: $navigation-height;
    margin: 0;
    padding: 0 $xs-spacing;

    @media (min-width: $small-screen) {
      padding-left: $navigation-padding;
      padding-right: $navigation-padding;
    }

    .text {
      display: none;
      font-weight: $weight-light;
      font-size: 0.8em;
      margin-left: 0.5em;
      position: relative;
      top: -0.1em;

      @media (min-width: $small-screen) {
        display: inline-block;
      }
    }
  }

  // Nav menu

  nav {
    float: none;
    min-height: $navigation-height;

    @include media($horizontal-bar-mode) {
      float: left;
    }
  }

  // The nav items

  .navigation-menu > li {
    color: $navigation-color;
    display: inline-block;
    line-height: $navigation-height;
    width: auto;

    a {
      color: $navigation-color;
      display: inline-block;
      font-weight: 400;
      padding-left: $small-spacing;
      padding-right: $small-spacing;
      text-decoration: none;

      &:focus,
      &:hover {
        color: $navigation-color-hover;
      }
    }

    &.active a {
      color: $navigation-color-hover;
    }
  }

  .secondary-navigation {
    height: $navigation-height;
    position: relative;

    > li {
      border-left: 1px solid var(--brand-neutral-cool-200);

      &:last-of-type {
        border-right: 1px solid var(--brand-neutral-cool-200);
      }
    }

    .secondary-navigation__toggle {
      cursor: pointer;
      display: block;
      padding: 0 1.25em;
      margin: 0;
    }

    .secondary-navigation__address {
      display: none;

      @media (min-width: $medium-screen) {
        display: inline-block;
        padding: 0 $small-spacing;
      }
    }

    .icon {
      font-size: 1.2em;
    }
  }

  .navigation-share {
    display: none;
    float: left;
    height: $navigation-height;
    align-items: center;

    @media (min-width: 485px) {
      display: flex;
    }
  }

  // Elements on the far right

  .navigation-tools {
    clear: none;
    display: block;
    float: right;
    height: $navigation-height;

    @media (min-width: $small-screen) {
      margin-right: $navigation-padding;
    }

    .avatar {
      @include square(32px);
      @include circle-photo;
      border: none;
      display: inline-block;
      line-height: $navigation-height;
      margin: 0;
      padding: 0;
      opacity: 0.7;
      transition: opacity $base-easing $base-duration;
      vertical-align: middle;
    }

    .avatar--company {
      @include square(38px);
      opacity: 1;
    }

    .avatar + .text {
      margin-left: $small-spacing;
    }

    a:hover .avatar {
      opacity: 1;
    }
  }
}

.nav-tabs {
  margin: 0 auto $large-spacing;

  @media (min-width: $medium-screen) {
    max-width: 80%;
  }
}

.nav-tabs + .button-group {
  margin-bottom: 0;
}

.preview-bar {
  margin-bottom: -($xl-spacing);
  padding: $small-spacing;

  .container {
    margin: 0 auto;
    max-width: $xl-content-width-max;
  }

  a {
    margin-left: $small-spacing;

    @media (min-width: $medium-screen) {
      margin-left: $large-spacing;
    }

    .icon {
      color: $action-color;
      padding: 0 $xs-spacing;
    }
  }
}

.img-logo--nav {
  @include square(60px);
}
