@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$boarder-radius: 20px;

.root {
  border-radius: $boarder-radius;
  padding: $xxs-spacing $small-spacing $xs-spacing;
  margin: $xs-spacing;
  color: var(--brand-neutral-cool-600);
  position: relative;

  &:hover {
    background-color: var(--brand-surface-layer-800);
  }

  > label:before {
    content: close-quote;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.startIcon {
  margin-right: $xs-spacing;
}

.endIcon {
  margin-left: $xs-spacing;
}

.checked {
  background-color: var(--brand-surface-layer-800);
  border-color: var(--brand-surface-layer-800);
  color: var(--brand-neutral-cool-900);
}

.focused {
  border-color: var(--brand-surface-layer-800);

  &:hover {
    border-color: var(--brand-surface-layer-800);
  }
}
