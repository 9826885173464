@use '../../../../../scss/themes/mixins' as themedMixins;
@import '../../../../../scss/base/variables';

$bgPositionVertAlign: calc(50% + 255px);
$br: 8px;

.container {
  // fallback for browsers that don't support image-set
  background-image: url('#{$assets_base}img/16x9-mobile-app-outside-natural@1x.jpg');
  background-image: -webkit-image-set(
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@1x.jpg') 1x,
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@2x.jpg') 2x);
  background-image: image-set(
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@1x.jpg') 1x,
    url('#{$assets_base}img/16x9-mobile-app-outside-natural@2x.jpg') 2x);

  background-repeat: no-repeat;
  background-size: 1200px;
  background-position: 0px $bgPositionVertAlign; // could add media queries to adjust the x value if we want the image to slide behind the text
  border-radius: $br;
  @include themedMixins.neutralDown-900;
}

.colorLayer {
  border: 1px solid var(--brand-primary-border-100);
  border-radius: $br;
  background-color: var(--brand-primary-50);
  opacity: .8;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 950px) {
  .container {
    background-position: -30px $bgPositionVertAlign;
  }
}
@media only screen and (max-width: 800px) {
  .container {
    background-position: -115px $bgPositionVertAlign;
  }
}
@media only screen and (max-width: 700px) {
  .container {
    background-position: -150px $bgPositionVertAlign;
  }
}

.contents {
  padding-left: $large-spacing;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 41px 0 36px 36px;
  width: 50%;
}

.header {
  color: var(--brand-primary-900);
  padding-bottom: 5px;
  text-align: left;
}

.description {
  color: var(--brand-primary-800);
  padding-bottom: 25px;
}

.buttonLinkText {
  margin-right: 0.75rem;
}