@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.container {
  display: inline-flex;
  height: 100%;
  align-items: center;

  @include mediumScreen {
    position: relative;
  }

  .button {
    position: relative;
    height: 46px;
    width: 46px;
    color: var(--brand-neutral-cool-700);
    outline: none;
    padding: 12px 8px;

    &:hover {
      color: var(--brand-hb-white);
    }

    &:focus {
      color: var(--brand-secondary-600);
    }
  }

  .smallButton {
    padding: 6px 8px;
    height: 31px;
    font-size: $small-font-size;
  }
}

.dropdownContainer {
  display: none;
  position: absolute;
  width: 100vw;
  top: calc(100% + #{$xxxs-spacing});
  left: 0px;
  z-index: 3;
  background-color: var(--brand-surface-elevation-300);

  @include mediumScreen {
    left: calc(0% - #{$small-spacing});
    box-shadow: $large-space-shadow;
    border-radius: 0.25em;
  }

  &.open {
    display: flex;
    flex-direction: column;
  }
}

// Secondary Action Button Styles

.buttonWrapper {
  display: inline-flex;

  &:hover {
    .verticalRule {
      opacity: 0;
    }
  }
}

.verticalRule {
  border-right: 1px solid var(--brand-neutral-cool-200);
  margin-top: 8%;
  margin-bottom: 8%;

  &:hover {
    cursor: pointer;
  }
}

.dropdownToggleButton {
  height: inherit;
  width: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 0 0.25em 0.25em 0;

  &:hover {
    path {
      fill: var(--brand-hb-white);
    }
  }

  &:focus {
    outline: 0.125em solid var(--brand-neutral-cool-900);
  }
}

.smallDropdownToggleButton {
  height: $nav-header-height * 0.5;
}
