@use '../../../../../../../scss/base/variables' as vars;

.root {
  cursor: pointer;
  border-radius: 8px;
  padding: vars.$xs-spacing 0;
  border: 1px solid transparent;
  color: var(--brand-neutral-cool-800);

  &:hover {
    margin-left: -20px;
    background: var(--brand-secondary-400);
    color: var(--brand-hb-white);

    span {
      margin-left: 20px;
    }
  }

  &:active {
    margin-left: -20px;
    background: var(--brand-secondary-300);
    color: var(--brand-hb-white);

    span {
      display: inline-block;
      margin-left: 20px;
    }
  }
}

.focused {
  margin-left: -20px;
  border: 1px solid var(--brand-secondary-400);

  span {
    display: inline-block;
    margin-left: 20px;
  }
}

.more {
  font-weight: vars.$weight-semibold;
  font-size: vars.$small-font-size;
  margin-top: vars.$small-spacing;
  padding: vars.$xs-spacing 0 !important;
  display: flex;
  align-items: center;

  > svg {
    margin-top: vars.$xxs-spacing;
    margin-left: vars.$xs-spacing;
  }
}

.moreFocusedBtn {
  color: var(--brand-secondary-300);

  > svg > path {
    fill: var(--brand-secondary-300);
  }
}

.divider {
  border: none;
  border-top: 1px dashed var(--brand-neutral-cool-200);
  height: 1px;
  width: 100%;
  left: 20px;
  margin: vars.$xxs-spacing 0;
}

.chevron {
  margin-left: vars.$xxs-spacing;
  transform: rotate(-90deg);
}
