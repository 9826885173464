@use '../themes/mixins' as themedMixins;

@mixin number {
  color: var(--brand-primary-600);
  font-size: $number-font-size;
  font-weight: $weight-light;
  text-align: center;
  text-shadow: $base-stroke-shadow;
  transition: color $short-duration $base-easing,
    opacity $short-duration $base-easing;
}

@mixin font-small {
  font-size: em(14);
}

@mixin font-medium {
  font-size: em(18);
}

@mixin font-xs {
  font-size: em(13);
}

@mixin font-quiet {
  color: $brand-quiet;
}

@mixin heading {
  font-family: $heading-font-family;
  font-size: $base-font-size; // remove
  font-weight: $base-font-weight; // remove
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  text-align: center;
}

@mixin h1 {
  @include heading;
  font-size: em(36);
  font-weight: $weight-light;
}

@mixin h2 {
  @include heading;
  font-size: em(24);
  font-weight: $weight-light;
}

@mixin h3 {
  @include heading;
  font-size: em(18);
  font-weight: $weight-light;
  line-height: 1.4;
}

@mixin h4 {
  @include heading;
}

@mixin h5 {
  @include heading;
  @include font-small;
  font-weight: $weight-regular;
  color: $brand-quiet;
}

@mixin h6 {
  @include heading;
  @include font-small;
  @include font-quiet;
  text-transform: uppercase;
}

@mixin size($w, $h) {
  width: $w;
  height: $h;
}

@mixin square($dim) {
  width: $dim;
  height: $dim;
}

@mixin truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin truncate-single-line {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin vertical-align-text-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin fixed-space-between($space: $xs-spacing) {
  margin-left: $space;

  &:nth-child(1) {
    margin-left: 0;
  }
}

@mixin button-base {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--brand-secondary-600);
  border-radius: $base-border-radius;
  border: none;
  color: var(--brand-hb-white);
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $weight-regular;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  transition: background-color $base-duration $base-easing,
    color $base-duration $base-easing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: var(--brand-secondary-500);
    color: $white;

    .icon {
      color: $white;
    }
  }

  &[disabled] {
    opacity: 0.5;
  }

  .icon {
    margin-right: $xs-spacing;
    transition: color $base-duration $base-easing;
  }
}

@mixin button--loud {
  background: var(--brand-primary-600);

  &:hover,
  &:focus {
    background-color: var(--brand-primary-700);
  }
}

@mixin card-base {
  @include card-padding;
  background: var(--brand-surface-elevation-300);
  @include themedMixins.neutralDown-400;
  border-radius: $large-border-radius;

  @media (min-width: $medium-screen) {
    margin-bottom: $xl-spacing;
  }

  header {
    margin-bottom: $base-spacing;
    position: relative;

    @include header-actions;
  }
}

@mixin header-actions {
  .actions,
  .list-actions {
    @include position(absolute, 0.1em 0 0 auto);
    li {
      font-size: 1.1em;
    }
    padding-right: $xs-spacing;
  }

  .actions-left,
  .list-actions-left {
    @include position(absolute, 0.1em auto 0 $xs-spacing);
    li {
      font-size: 1.1em;
    }
    padding-left: $xs-spacing;
  }

  .actions select {
    text-align: center;
  }
}

@mixin card-padding {
  padding: $card-pad-vertical $card-pad-horizontal;

  @media (min-width: $medium-screen) {
    padding: $card-pad-vertical-lg $card-pad-horizontal-lg;
  }
}

@mixin circle-photo {
  border: $border-circle;
  border-radius: 10em;
  background-size: cover;
  background-position: 50% 15%;
  display: block;
}

@mixin stretch-to-card-edge {
  margin-left: (-$card-pad-horizontal);
  margin-right: (-$card-pad-horizontal);

  @media (min-width: $medium-screen) {
    margin-left: (-$card-pad-horizontal-lg);
    margin-right: (-$card-pad-horizontal-lg);
  }
}

@mixin card-pad-horizontal {
  padding-left: $card-pad-horizontal;
  padding-right: $card-pad-horizontal;

  @media (min-width: $medium-screen) {
    padding-left: $card-pad-horizontal-lg;
    padding-right: $card-pad-horizontal-lg;
  }
}

@mixin negative-card-margin-bottom {
  margin-bottom: (-$card-pad-vertical);

  @media (min-width: $medium-screen) {
    margin-bottom: (-$card-pad-vertical-lg);
  }
}

@mixin reveal-vert($max-height: 10em) {
  overflow: hidden;
  transition: all $base-duration $base-easing;

  &.ng-leave.ng-leave-active,
  &.ng-enter {
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    transform: translateY(1em);
  }

  &.ng-enter.ng-enter-active,
  &.ng-leave {
    max-height: $max-height;
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin spinning-border {
  position: relative;

  &:after {
    @include position(absolute, -7px 0 -7px 0);
    animation: spin 30s linear infinite;
    background: url(#{$assets_base}img/avatar-border-dotted.svg) no-repeat 50% 0;
    background-size: contain;
    content: '';
    pointer-events: none;
  }
}

@mixin disc-for-list-item($spacing: 1em) {
  &:before {
    content: '\000B7';
    font-weight: bold;
    float: left;
    margin: 0 0 0 (-$spacing);
  }
}

@mixin default-ul {
  list-style: none;
  margin-bottom: $base-spacing;
}

@mixin default-ol {
  list-style-type: decimal;
  margin-bottom: $base-spacing;
  padding-left: 1.25em;
}

@mixin flex-parent-with-nested-flex-children($flex-grow, $flex-shrink) {
  // Flexing objects with other nested flex objects
  // can break in IE11 without this. Normally you'd
  // use a real value for flex-basis instead of 0%
  // https://github.com/philipwalton/flexbugs/issues/71
  flex: $flex-grow $flex-shrink 0%;
}

@mixin form_fields_wrap {
  @media (min-width: $medium-screen) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .form-group {
      flex: 0 1 47%;
    }
  }
}

@mixin nav-dropdown {
  $width: 350px;
  @include position(fixed, $global-nav-height 0 0 0);
  background-color: $darker-blue;
  box-shadow: $large-space-shadow;
  display: none;
  overflow-y: auto;
  z-index: $z-spotlight;

  @media (min-width: $medium-screen) {
    @include position(absolute, $global-nav-height auto auto 30px);
    margin-left: (-$width * 0.5);
    width: $width;
  }

  h3 {
    padding: $small-spacing $base-spacing;
    margin: 0;
    border-top: 1.5px solid var(--brand-primary-600);
    border-bottom: 2px solid $dark-blue;
  }

  h6 {
    margin: $base-spacing;
  }

  .notifications-close {
    @include square(1em);
    float: right;
    font-size: 16px;
    margin-right: 1em;

    .icon {
      font-size: 1em;

      @media (min-width: $medium-screen) {
        display: none;
      }
    }
  }
}

@mixin targetIE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin bodyBackground {
  background-repeat: no-repeat;
  background-attachment: initial;
  background-size: contain, auto, auto;
  background-image: var(--brand-background-image);
}

@mixin hideLabel {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin pocketAmount($width: 105px) {
  .pocketAmountBox {
    padding: $small-spacing;
    background-color: var(--brand-success-50);
    border: 1px solid var(--brand-primary-border-50);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: $medium-border-radius;
    width: $width;
    bottom: 2px;
    right: 1px;
  }
}

@mixin srOnly {
  visibility: hidden;
  position: absolute;
}
