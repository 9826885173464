@use "sass:math";

@import '../../../../scss/base/variables';

$animation: 'line-scale';
$barCount: 5;
$duration: 1s;
$count: infinite;
$cubicBezier: cubic-bezier(0.2, 0.68, 0.18, 1.08);

$increment: math.div(1, $barCount) * 0.5;

@keyframes lineScale {
  0% {
    transform: scaley(1);
  }
  50% {
    transform: scaley(0.4);
  }
  100% {
    transform: scaley(1);
  }
}

@for $i from 1 through $barCount {
  $value: (0 - (($barCount - $i) * $increment)) + s;

  .lineScale > div:nth-child(#{$i}) {
    animation: lineScale $duration $value $count $cubicBezier;
  }
}

.lineScale > div {
  background-color: var(--brand-neutral-cool-900);
  width: 0.25em;
  height: 2.1875em;
  border-radius: 0.125em;
  margin: 0.125em;
  animation-fill-mode: both;
  display: inline-block;
}
