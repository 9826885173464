@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$gap: $small-spacing;
$fill-width: 250px;

.container {
  display: flex;
  flex-direction: column;
  margin: $base-spacing -1*$base-spacing $small-spacing;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.slideOpenContainer {
  position: relative;
  display: flex;
  justify-content: center;
  visibility: hidden;
  width: calc(40px + 2*#{$gap});
  max-width: $fill-width;
  padding-left: $gap*1.5;
  overflow: hidden;
  box-sizing: border-box;
  transition: width $base-duration $base-easing;
  align-items: center;

  &.disableShow {
    opacity: 0;
  }

  .icon {
    flex-shrink: 0;

    $icon-sweep-duration: $base-duration*.75;
    &:not(.failure) {
      position: relative;
      transform: rotateZ(45deg);
      border-color: transparent;

      background-color: var(--brand-neutral-cool-200);
      transition: background-color $base-duration $base-easing .25*$icon-sweep-duration;

      > i {
        transform: rotateZ(-45deg);

        opacity: 0;
        transition: opacity $base-duration $base-easing;
      }

      &:before, &:after {
        content: close-quote;
        position: absolute;
        top: -3px;
        left: -3px;
        bottom: -3px;
        right: -3px;
        border: 3px solid;
        border-radius: 100%;
        transform: rotateZ(0deg);
      }

      &:before {
        border-color: transparent;
        border-right-color: var(--brand-success-700);

        transition: transform $icon-sweep-duration linear 0s;
      }

      &:after {
        border-color: transparent;

        background-color: --brand-neutral-cool-200;
        border-right-color: --brand-neutral-cool-200;

        transition:
          border-bottom-color 0s linear .25*$icon-sweep-duration,
          border-left-color 0s linear .5*$icon-sweep-duration,
          border-top-color 0s linear .75*$icon-sweep-duration,
          border-right-color 0s linear .25*$icon-sweep-duration,
          background-color $base-duration $base-easing .25*$icon-sweep-duration;
      }
    }
  }

  &.ready {
    position: relative;
    width: 100%;
    visibility: visible;

    .icon {
      &:not(.failure) {
        background-color: transparent;

        > i {
          opacity: 1;
        }

        &:before {
          transform: rotateZ(360deg);
        }

        &:after {
          background-color: transparent;
          border-color: var(--brand-success-700);
          border-right-color: transparent;
        }
      }
    }
  }

  .resultText {
    max-width: calc(100% - 40px);
    white-space: pre-line;
    padding-left: $xs-spacing;
    padding-right: $gap*1.5;
  }
}
