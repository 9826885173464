.root {
  position: relative;
  display: block;

  &.aspect {
    &-16_9 {
      padding: 56.25% 0 0;
    }
    &-fixed {
      padding: 100% 0 0;
    }
    &-none {
      padding: 0;
    }
    &-4_3 {
      padding: 75% 0 0;
    }
  }
}

.iframe {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
