@import 'bar-charts';
@import 'line-charts';
@import 'tips';

.chart-container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  overflow: hidden;

  svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.chart-container--timeline {
    padding-bottom: 30%; /* holds aspect ratio */
  }

  &.chart-container--bar-chart {
    margin: 0 0 $base-spacing;
    padding-bottom: 30%; /* holds aspect ratio */
  }

  &.chart-container--line-chart {
    padding-bottom: 40%; /* holds aspect ratio */
  }
}
