.pro-summary {
  align-items: center;
  display: flex;
  justify-content: center;

  .avatar {
    @include circle-photo;
    @include square(em(64));
    flex: 0 0 auto;
    margin: 0 $base-spacing 0 0;

    &.empty {
      background: transparentize($action-color, 0.9);
    }
  }

  .contact-info {
    flex: 0 1 auto;
  }

  .lead {
    display: block;
    font-size: em(18);
    line-height: 1;
    margin-bottom: $xs-spacing;
    text-align: left;
  }

  .list-disc {
    margin-bottom: 0;
    text-align: left;

    // IE10 Hack: lists jump onto same line without this
    display: inline-block;
    width: 100%;
    // /IE10 Hack
  }

  .list-disc li {
    display: inline-block;
  }

  p,
  .list-disc li {
    font-size: em(14);
    line-height: 1.75;
    margin-bottom: 0;
    text-align: left;
  }
}

.pro-summary--small {
  .avatar {
    @include square(em(48));
    margin-right: $small-spacing;
  }

  .lead {
    font-size: em(16);
  }

  p,
  .list-disc li {
    font-size: em(13);
  }
}

.pro-summary--subtle {
  .avatar {
    @include square(em(32));
    margin-right: $small-spacing;
    opacity: 0.9;
  }

  .lead {
    color: $brand-quiet;
    font-size: em(16);
    margin-bottom: 2px;
  }
}

.pro-summary--left {
  justify-content: flex-start;
}

.pro-summary--swapped {
  .avatar {
    margin-left: $base-spacing;
    margin-right: 0;
    order: 2;
  }

  &.pro-summary--small .avatar {
    margin-left: $small-spacing;
  }
}
