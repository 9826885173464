@media (min-width: $small-screen) {
  .columns {
    display: flex;

    .col {
      flex: 1;
    }

    &.columns--spaced .col {
      @include fixed-space-between(1em);
    }
  }
}
