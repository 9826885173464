ul.default {
  @include default-ul;
}

ol.default {
  @include default-ol;

  li {
    margin-bottom: $small-spacing;
  }
}

.list {
  @include default-ul;

  &.list--centered {
    text-align: center;
  }
}

.list-disc {
  margin-bottom: $base-spacing;

  li {
    margin-bottom: $small-spacing;
    padding-left: 1em;
    text-align: left;

    @include disc-for-list-item;
  }
}

@media (min-width: $medium-screen) {
  .list-disc + .list-disc {
    margin-top: (-$small-spacing);
  }

  .list-disc--inline {
    @include clearfix();

    li {
      float: left;
      margin-bottom: 0;
      margin-left: 1em;
      margin-right: 0.6em;
      padding: 0;

      &.break-line {
        clear: left;
      }

      &.break-line,
      &:first-of-type {
        margin-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .list-disc--tight {
    li {
      $spacing: 0.3em;
      margin-left: $spacing;
      margin-right: $spacing;

      @include disc-for-list-item($spacing * 2);
    }
  }
}

.list-disc--center {
  text-align: center;

  li {
    display: inline-block;
    float: none;
  }

  @media (max-width: $medium-screen) {
    li {
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }
}

.list-disc--address {
  line-height: $base-spacing;
  color: $white;
  display: flex;
  flex-direction: column;

  li {
    float: left;
  }
}

.list-tabled {
  .list-tabled {
    margin: $small-spacing 0;
  }

  > li {
    padding: $small-spacing;

    &[ui-sref] {
      opacity: 1;
      transition: background-color $short-duration $base-easing,
        opacity $base-duration $base-easing;
    }

    &.quiet {
      opacity: 0.4;
    }
  }

  &:hover > li[ui-sref] {
    opacity: 0.75;

    &.quiet {
      opacity: 0.4;
    }
  }

  h3 {
    margin-bottom: 0;
    text-align: left;
  }

  .avatar {
    @include circle-photo;
    @include square(3.6em);
    float: left;
    margin: 0 $small-spacing 0 0;
  }

  .explain {
    text-align: left;
    opacity: 0.65;
    transition: opacity $base-easing $base-duration;
  }

  .editable,
  .editable-spans span {
    margin: 0 0 0 $xs-spacing;

    &:first-of-type {
      margin-left: 0;
    }
  }

  > li[ui-sref]:hover {
    background: rgba(255, 255, 255, 0.05);
    opacity: 1;

    .explain {
      opacity: 1;
    }

    .editable,
    .editable-spans span {
      border-bottom: 1px dashed darken($brand-xs-quiet, 15);
    }

    &.quiet {
      background: none;
      cursor: default;
      opacity: 0.4;
    }
  }

  &.list-tabled--all-clients {
    min-height: 10em;

    @media (min-width: $medium-screen) {
      min-height: 20em;
    }
  }
}

.list-ordered-large {
  $marker-size: 2em;
  counter-reset: li;
  overflow: hidden;
  transition: max-height $long-duration $base-easing;
  max-height: 1000px;
  position: relative;

  > li {
    margin-bottom: $large-spacing;

    h3,
    p,
    .explain,
    .sub-explain {
      text-align: left;
    }

    h3 {
      margin-bottom: $xs-spacing;
    }

    .explain {
      color: $brand-quiet;
      font-size: 14px;
      margin-bottom: $xs-spacing;
    }

    .notification {
      font-size: 14px;
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: $medium-screen) {
      padding-left: ($marker-size + $base-spacing);
      position: relative;

      // marker
      &:before {
        @include position(absolute, 0 0 0 0);
        @include square($marker-size);
        border: 1px solid $brand-xs-quiet;
        border-radius: 10em;
        content: counter(li); // set to current numeral
        counter-increment: li; // start at 1 instead of 0
        font-size: 18px;
        line-height: $marker-size * 0.9;
        text-align: center;
      }
    }
  }

  &.collapsed {
    max-height: 90px;

    &::after {
      @include position(absolute, 0 0 0 0);
      display: block;
      background-image: linear-gradient(
        to top,
        $card-background-color,
        transparentize($card-background-color, 0.5) 100%
      );
      content: '';
    }

    & + .collapse-link {
      position: relative;
      margin-top: -($base-spacing);
      text-align: center;
      z-index: 1;
    }
  }
}

.list-actions {
  @include clearfix;
  float: right;

  li {
    display: inline-block;
    font-size: 14px;
    margin-left: 1em;
  }

  .icon {
    margin-right: 0.25em;
  }

  @media (max-width: $small-screen) {
    span {
      display: none;
    }
  }

  &.list-actions--inline {
    float: none;
    display: inline-block;
  }
}
