@use '../../../scss/themes/mixins' as themedMixins;
@import '../../../scss/base/variables';

.container {
  padding: $base-spacing;
  background-color: var(--brand-surface-layer-50);
  border-radius: $medium-border-radius;
  border: 1px solid var(--hb-colors-card-border);
}

.header {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;
}

.content {
  margin: 0 1em;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  color: var(--brand-primary-accent-800);
  white-space: nowrap;
}

.icon {
  margin-bottom: 6px;
  font-size: 20px;
  color: var(--brand-primary-accent-800);
}

.rule {
  height: 0px;
  opacity: 0.5;
  border: solid 1px var(--brand-primary-accent-800);
  border-bottom: 0;
  width: 100%;
  margin-bottom: 0.4em;
}
