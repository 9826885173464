table {
  @include font-feature-settings('kern', 'liga', 'tnum');
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: $base-border;
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  @include truncate;
  border-bottom: 1px solid var(--brand-primary-border-200);
  padding: $small-spacing $small-spacing $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}

.value-table {
  th,
  .table-label {
    @include font-xs();
    color: $brand-quiet;
  }

  .table-label {
    width: 25%;
  }
}
