.options {
  .option h2,
  .option h3 {
    text-align: center;
  }

  .option {
    padding: $base-spacing 5%;
    width: 100%;
  }

  .option:first-child {
    background: transparentize(white, 0.925);
  }

  .option:last-child {
    background: transparentize(black, 0.925);
  }

  p,
  .list-disc {
    font-size: em(14);
  }
}

@media (min-width: $small-screen) {
  .options {
    display: flex;

    .option {
      flex: 1 1 50%;
    }
  }
}
