@use '../../../../scss/base/variables' as vars;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  margin-top: vars.$small-spacing;
}
