// typography.scss sets global anchor styles that were overriding the design system "link styled as button" styles
// here we have to re-override the global styles to match the design system styles

.anchorOverrideBlack {
  a:active, a:focus, a:hover {
    color: var(--hb-colors-black);
  }
}

.anchorOverrideWhite {
  a:active, a:focus, a:hover {
    color: var(--hb-colors-white);
  }
}
