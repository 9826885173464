@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';
@import '../Panel/variables.module.scss';

.pageContents {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.outerCarousel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $large-spacing;

  @include mobileHeight {
    margin-bottom: $base-spacing;
  }

  .navWrapper {
    margin-top: -1 * $small-spacing;
  }

  .navButton {
    min-height: 32px;
    border: 1px solid var(--brand-primary-border-100);
    opacity: 1;
    transition: opacity 0.15s ease-out;
  }
}

.innerCarousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 100%;
  max-width: 200px;
  margin: 0 $small-spacing;

  @include smallScreen {
    max-width: 240px;
  }
}

.indicatorBar {
  display: flex;
  align-items: center;
  margin-top: $xs-spacing;

  .bubble {
    border-radius: 100%;
    background-color: var(--brand-neutral-cool-500);
    @include square($xs-spacing);
    margin: 0.15em;

    &.active {
      background-color: var(--brand-neutral-cool-900);
    }
  }
}

.pageBody {
  @include panelOverlayPaddingH;
  @include panelPaddingBottom;
  @include themedMixins.neutralDown-400;

  background-color: var(--brand-surface-elevation-300);
  border-radius: 0 0 $large-border-radius $large-border-radius;
  border: 1px solid var(--brand-primary-border-50);
  padding-top: $panelPaddingV;

  position: relative;
  flex-grow: 1;
  margin-bottom: $base-spacing;
  text-align: center;
  overflow: visible;
}

.footSpacer {
  flex-grow: 1;
}

.footer {
  @include panelOverlayPaddingH;
  @include themedMixins.neutralDown-500;
  border: 1px solid var(--brand-primary-border-50);
  padding-bottom: 1.5rem;
  margin-top: $section-spacing;
  background-color: var(--brand-surface-elevation-300);
  border-radius: $large-border-radius;
}

.disclaimer {
  margin-top: 3em;
}

.swipeEnabled {
  .pageBody {
    transition: opacity 0.15s ease-in;
  }

  &.swipeActive .pageBody {
    transition: opacity 0s;
  }

  &.mounting .pageBody {
    opacity: 0 !important;
  }

  &.navigatingLeft .pageBody {
    opacity: 0 !important;
    left: -100% !important;
    transition: left 0.25s ease-out, opacity 0.25s ease-in-out;
  }

  &.navigatingRight .pageBody {
    opacity: 0 !important;
    left: 100% !important;
    transition: left 0.25s ease-out, opacity 0.25s ease-in-out;
  }

  &.navigationCancel .pageBody {
    left: 0 !important;
    transition: left 0.15s ease-out;
  }
}