@import '../../../scss/base/variables';

$control-font-family: $base-font-family;
$control-border: none;
$control-spacing-horizontal: 0.75em;
$control-spacing-vertical: 0.25em;
$control-padding: $control-spacing-vertical $control-spacing-horizontal * 2
  $control-spacing-vertical $control-spacing-horizontal;
$control-outline-hover: none;

@mixin control {
  background-color: var(--brand-surface-layer-300);
  color: var(--brand-neutral-cool-900);
  border: $control-border;
  border-radius: $medium-border-radius;
  font-family: $control-font-family;
  font-size: 1em;
  font-weight: normal;
  line-height: 2;
  margin: 0;
  padding: $control-padding;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
  border: 1px solid transparent;

  &::placeholder {
    color: var(--brand-neutral-cool-500);
  }

  &:focus,
  &:hover,
  &:active {
    outline: $control-outline-hover;
    background-color: var(--brand-surface-layer-500);
  }
}
