body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: var(--brand-hb-white);
  font-family: $base-font-family;
  -webkit-font-feature-settings: "pnum";
  // font-feature-settings: "pnum"; // this will break IE10 & 11 for some reason
  // font-size: $base-font-size * .9;
  font-weight: $base-font-weight;
  line-height: $base-line-height;

  @media (min-width: $medium-screen) {
    font-size: $base-font-size;
  }
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h1 + h2,
h1 + .h2,
.h1 + h2,
.h1 + .h2 {
  margin-top: (-$small-spacing);
  margin-bottom: $base-spacing;
}

h3,
.h3 {
  @include h3;
}

h2 + .subhead,
.h2 + .subhead {
  margin-top: (-$xs-spacing);
  margin-bottom: $base-spacing;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}

em {
  color: var(--brand-primary-600);
  font-style: normal;

  &.interest {
    color: var(--brand-success-700);
  }
}

strong {
  font-weight: $weight-semibold;
}

p {
  margin: 0 0 $small-spacing;
  font-weight: $weight-regular;
}

.subhead {
  color: var(--brand-neutral-cool-700);
}

.card p {
  text-align: center;
}

.sub-explain {
  color: var(--brand-neutral-cool-700);
}

.disclaimer-text {
  color: var(--brand-neutral-cool-700);
  text-align: left;
}

.info-label {
  @include font-xs();
  color: var(--brand-neutral-cool-700);
  text-transform: uppercase;
}

a {
  color: var(--brand-secondary-600);
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:hover {
    color: var(--brand-secondary-400);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $border-quiet;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

blockquote {
  margin: $small-spacing $base-spacing;
  opacity: 0.75;
}

address {
  font-style: normal;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

.center,
.center p {
  text-align: center;
}

.interest .number {
  color: var(--brand-success-700);
}

[animated-number="money"]:before {
  content: "$";
}

[animated-number="money"].number-negative:before {
  content: "-$";
}

[animated-number="percent"]:after {
  content: "%";
}
