@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$panelPaddingH: $small-spacing;
$panelPaddingV: $large-spacing;
$panelPadding: $panelPaddingV $panelPaddingH;

$panelPaddingMediumScreenH: $base-spacing;
$panelPaddingMediumScreenV: $xl-spacing;
$panelPaddingMediumScreen: $panelPaddingMediumScreenV $panelPaddingMediumScreenH;

$containerPadSpace: $base-spacing;

$panelOverlayPaddingH: $base-spacing;

@mixin panelPaddingH {
  padding-left: $panelPaddingH;
  padding-right: $panelPaddingH;

  @media (min-width: $medium-screen) {
    padding-left: $panelPaddingMediumScreenH;
    padding-right: $panelPaddingMediumScreenH;
  }
}

@mixin panelPaddingTop {
  padding-top: $panelPaddingV;

  @media (min-width: $medium-screen) {
    padding-top: $panelPaddingMediumScreenV;
  }
}

@mixin panelPaddingBottom {
  padding-bottom: $panelPaddingV;

  @media (min-width: $medium-screen) {
    padding-bottom: $panelPaddingMediumScreenV;
  }
}

@mixin escapePanelPaddingH {
  margin-left: -1 * $panelPaddingH;
  margin-right: -1 * $panelPaddingH;

  @media (min-width: $medium-screen) {
    margin-left: -1 * $panelPaddingMediumScreenH;
    margin-right: -1 * $panelPaddingMediumScreenH;
  }
}

@mixin escapePanelPaddingBottom {
  margin-bottom: -1 * $panelPaddingV;

  @media (min-width: $medium-screen) {
    margin-bottom: -1 * $panelPaddingMediumScreenV;
  }
}

@mixin panelOverlayPaddingH {
  padding-left: $panelOverlayPaddingH;
  padding-right: $panelOverlayPaddingH;
}

@mixin escapePanelOverlayPaddingH {
  margin-left: -1 * $panelOverlayPaddingH;
  margin-right: -1 * $panelOverlayPaddingH;
}