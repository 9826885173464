@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';

$arrow-margin: -30px;

.slickArrow {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $small-border-radius;
  color: var(--brand-neutral-cool-600);
  height: 30px;
  font-size: $l-font-size;
  font-weight: $weight-bold;
  width: 30px;
  transition: ease-in-out width .35s ease-in-out;
  cursor: pointer;
  background: none;

  &.disabled {
    opacity: .25;
    cursor: default;
  }

  &:not(.disabled):hover {
    color: var(--brand-neutral-cool-900);
  }

  &::before {
    content: none;
  }

  [class="slick-list"] {
    padding: 0px;
  }  
}

.slickNext {
  right: $arrow-margin;
}

.slickPrev {
  left: $arrow-margin;
}

[class="slick-dots"] {
  button::before {
    color: var(--brand-neutral-cool-800) !important;
  }

  &:hover {
    color: var(--brand-neutral-cool-700);
  }

}

[class="slick-active"] {
  button::before {
    color: var(--brand-hb-white) !important;
  }
}

[class="slick-list"] {
  padding: 0px;
}

ul[class="slick-dots"] li {
  margin: 0;
}

// The below addresses height consistency issues
:global(div.slick-track) {
  display: flex;
  margin: 2px 0;
}

:global(div.slick-slide) {
  flex: 1;
  height: auto;

  > div {
    height: 100%;
  }
}
