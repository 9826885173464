
@use 'src/scss/base/variables' as vars;

$progress-bar-constant: 40px;
$line-height: 70px;

.wrapper {
  display: flex;
}

.bottom {
  border-radius: $progress-bar-constant 0 0 $progress-bar-constant;
  width: $progress-bar-constant;
}

.background {
  position: relative;
  flex: 2;
  height: $progress-bar-constant;
  background: var(--brand-neutral-cool-300);
}

.fill {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: $progress-bar-constant;
  padding-right: vars.$small-spacing;
}

.displayValue {
  color: var(--brand-hb-black);
}

.line {
  border-left: 1px dashed var(--brand-neutral-cool-700);
  height: $line-height;
  position: absolute;
  right: 0;
  bottom: -15px;
}

.label {
  position: absolute;
  right: 0;
  top: calc($progress-bar-constant + 15px);
  right: -$progress-bar-constant;
}

.end {
  width: 75px;
  height: $progress-bar-constant;
  background: linear-gradient(270deg,
    transparent 0%,
    var(--brand-neutral-cool-300) 80%,
    var(--brand-neutral-cool-300) 100%
  );
}

:export {
  defaultFillColor: var(--brand-success-700);
}
