@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.li {
  display: flex;
}

.iconCol {
  flex: 0 1 auto;
  margin-right: $small-spacing;
}

.textCol {
  flex: 1 1 auto;
}
