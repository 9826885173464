.callout {
  border-radius: $base-border-radius;
  display: flex;
  flex-flow: column;
  padding: $base-spacing;
  background-color: var(--brand-surface-layer-50);
  border: 1px solid var(--brand-primary-border-100);
  border-radius: $medium-border-radius;
}

p.callout__explanation {
  color: var(--brand-neutral-cool-700);
  text-align: left;
}

.callout__icon {
  $size: $callout-icon-size;
  margin: (-$size - 4) auto $xs-spacing;

  .icon {
    background-color: $card-background-color;
    font-size: $size;
  }
}

.callout__list li {
  color: var(--brand-neutral-cool-700);
  margin: 0 0 $small-spacing;
  max-width: 100%;
}

@media (min-width: $medium-screen) {
  .callout {
    align-items: center;
    flex-flow: row nowrap;
  }

  .callout__icon {
    flex: 0 0 $callout-icon-size;
    margin: 0 $base-spacing 0 0;

    .icon {
      position: relative;
      vertical-align: middle;
    }
  }

  .callout__explanation {
    flex: 2 1 50%;
    margin: 0 $small-spacing 0 0;
  }

  .callout__list {
    flex: 1 1 35%;
  }
}
