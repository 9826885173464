@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

$stepHeight: 12px;
$minorHeight: 20px;
$majorHeight: 30px;
$pinkHeight: 40px;
$gutterIconDim: 18px;
$topBuffer: 15px;
$sideBuffer: 0;
$bottomBuffer: 50px;
$scrollBarHide: 20px;
$scrollSpeed: 0.1s;
$tickWidth: 1px;

.root {
  $rulerWidth: var(--widthPx);
  $intervalWidth: var(--intervalPx);
  $homebotDelta: var(--homebot);
  $customerDelta: var(--customer);
  $major: var(--major);
  $minor: var(--minor);
  $majorOffset: var(--majorOffset);
  $minorOffset: var(--minorOffset);
  $valueWidth: calc(#{$intervalWidth} + #{$tickWidth});

  position: relative;
  width: 100%;
  min-height: 40px;
  padding-top: $topBuffer;
  padding-bottom: $bottomBuffer;
  box-sizing: content-box;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);

  height: 50px;
  min-width: 100px;
  max-width: 600px;

  .rulerContainer {
    width: $rulerWidth;
    top: $topBuffer;
    bottom: $bottomBuffer;
    height: calc(100% - #{$topBuffer} - #{$bottomBuffer});
    position: absolute;

    // Need in IE, justify-content: center; from parent did not center child
    left: 50%;
    margin-left: calc(#{$rulerWidth} / -2);

    & > * {
      box-sizing: content-box;
    }
  }

  .ruler {
    position: relative;
    height: 100%;
    width: $rulerWidth;
  }

  .viewable,
  .controllable,
  .hidden {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    padding: $topBuffer $sideBuffer $bottomBuffer;
  }

  &[data-varsLoaded] {
    .viewable {
      visibility: visible;
      transition-delay: $scrollSpeed;

      .ruler {
        transition: transform $scrollSpeed ease-out;
      }
    }
  }

  .viewable {
    visibility: hidden;
    padding: $topBuffer 0 $bottomBuffer;

    .ruler {
      transition: transform 0s;

      .ticks {
        position: absolute;
        width: calc(100% + #{$tickWidth});
        margin: 0 -0.5 * $tickWidth;
        left: 0;
        right: 0;
        bottom: 0;

        &.step {
          height: $stepHeight;
          background: repeating-linear-gradient(
            to right,
            var(--brand-neutral-cool-700),
            var(--brand-neutral-cool-700) 0px,
            var(--brand-neutral-cool-700) $tickWidth,
            transparent $tickWidth,
            transparent $valueWidth
          );
        }

        &.minor {
          height: $minorHeight;
          @if $minor {
            $minorInner: calc(#{$minor} * #{$valueWidth});
            width: calc(
              100% + #{$tickWidth} - (#{$valueWidth} * #{$minorOffset})
            );
            left: calc(#{$minorOffset} * #{$valueWidth});
            background: repeating-linear-gradient(
              to right,
              var(--brand-neutral-cool-700),
              var(--brand-neutral-cool-700) 0px,
              var(--brand-neutral-cool-700) $tickWidth,
              transparent $tickWidth,
              transparent #{$minorInner}
            );
          }
        }

        &.major {
          height: $majorHeight;
          @if $major {
            $majorInner: calc(#{$major} * #{$valueWidth});
            width: calc(
              100% + #{$tickWidth} - (#{$valueWidth} * #{$majorOffset})
            );
            left: calc(#{$majorOffset} * #{$valueWidth});
            background: repeating-linear-gradient(
              to right,
              var(--brand-neutral-cool-700),
              var(--brand-neutral-cool-700) 0px,
              var(--brand-neutral-cool-700) $tickWidth,
              transparent $tickWidth,
              transparent #{$majorInner}
            );

            display: flex;
            justify-content: space-between;

            .labelWrapper {
              position: absolute;
              bottom: 0;
              width: 0;
              color: var(--brand-neutral-cool-700);

              .label {
                bottom: calc(#{-1 * $gutterIconDim} - 4px);
                font-weight: $weight-regular;
                font-size: $xxs-font-size;
              }
            }
          }
        }
      }
    }

    .tick {
      $boldTickWidth: 3px;

      position: absolute;
      width: $boldTickWidth;
      z-index: 1;

      &.value {
        left: calc(50% - #{0.5 * $boldTickWidth});
        height: $pinkHeight;
        top: calc(#{$pinkHeight} - #{$majorHeight});
        background: var(--brand-primary-500);

        .label {
          top: -1 * $xl-font-size;
          font-size: $xl-font-size;
          font-weight: $weight-regular;
          color: var(--brand-primary-500);

          &.hidden {
            display: none;
          }
        }
      }

      &.green {
        background: var(--brand-success-700);
      }

      &.homebot {
        left: calc(#{$homebotDelta} * #{$valueWidth} - 1px);
        height: 5px;
        bottom: 0;
        width: calc(#{$tickWidth} + 1px);
        background: var(--brand-neutral-cool-900);
        color: var(--brand-neutral-cool-900);

        &.step {
          height: $stepHeight;
        }

        &.minor {
          height: $minorHeight;
        }

        &.major {
          height: $majorHeight;
        }

        .icon {
          position: absolute;
          width: $gutterIconDim;
          height: $gutterIconDim;
          bottom: calc(#{-1 * $gutterIconDim} - 4px);
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &.customer {
        left: calc(#{$customerDelta} * #{$valueWidth} - 1px);
        height: 0;
        bottom: 0;
        width: $tickWidth;
        color: var(--brand-neutral-cool-700);

        &:after {
          content: close-quote;
          position: absolute;
          left: 0;
          width: $tickWidth;
          background: repeating-linear-gradient(
            to bottom,
            #{var(--brand-neutral-cool-700)},
            #{var(--brand-neutral-cool-700)} 0px,
            #{var(--brand-neutral-cool-700)} 2px,
            transparent 2px,
            transparent 4px
          );
        }

        &.step {
          &:after {
            height: calc(#{$pinkHeight} - #{$stepHeight});
            bottom: $stepHeight;
          }
          height: $stepHeight;
        }

        &.minor {
          &:after {
            height: calc(#{$pinkHeight} - #{$minorHeight});
            bottom: $minorHeight;
          }
          height: $minorHeight;
        }

        &.major {
          &:after {
            height: calc(#{$pinkHeight} - #{$majorHeight} - 2px);
            bottom: $majorHeight;
          }
          height: $majorHeight;
        }

        .label {
          display: none;
        }

        .icon {
          position: absolute;
          width: $gutterIconDim;
          height: $gutterIconDim;
          bottom: $pinkHeight;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .label {
        position: absolute;
        bottom: calc(
          #{-1 * $gutterIconDim} - #{$small-font-size * $base-line-height} - 8px
        );
        font-size: $small-font-size;
        font-weight: $weight-semibold;
        left: 50%;
        transform: translateX(-50%);
      }

    }
  }

  .controllable {
    cursor: grab;
    z-index: 1;
    user-select: none;

    .rulerContainer {
      top: 0;
      bottom: -1 * $scrollBarHide;
      min-width: 100%;
      padding: $topBuffer 0 calc(#{$bottomBuffer} + #{$scrollBarHide});
      box-sizing: content-box;
      overflow-x: scroll;

      .ruler {
        width: #{$rulerWidth};
        padding: 0 50%;

        $buttonWidth: 15px;
        input {
          width: calc(100% + #{$buttonWidth});
          margin: 0 -0.5 * $buttonWidth;
          height: 100%;
          opacity: 0.001; // Firefox seems to think opacity: 0 is the same as visibility: hidden, need this so users can still focus the input...
        }

        label {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          visibility: hidden;

          &.onTop {
            visibility: visible;
            color: transparent;
            margin: 0;
            cursor: grab;
          }
        }

        input:focus + label.onTop {
          background: $input-background-color;
          outline: auto;
          outline-color: var(--brand-neutral-cool-700);
        }
      }
    }
  }
}

.grabbing {
  cursor: grabbing !important;

  * {
    cursor: grabbing !important;
  }

  .controllable {
    cursor: grabbing !important;
  }
}

:export {
  cssBaseDuration: #{$base-duration};
  cssTickWidth: #{$tickWidth};
}
