@use "src/scss/base/variables" as vars;

.flex {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: vars.$small-spacing;
  color: var(--brand-neutral-cool-900);

  svg {
    margin-bottom: vars.$xs-spacing;
  }

  @media (max-width: vars.$small-screen) {
    flex: 0;
    margin-top: 3rem;
  }
}

.noResultMessage {
  margin: 0px;
}
