@import '../../../scss/base/variables';
@import './control_variables.module';

.root {
  @include control;

  // In Apple's signature style, they define their own web standards and we've got to support them.
  // Any input or select must render at <= 16px font or iOS will force zoom for accessibility.
  // !important enforces default, can be overriden with subsequent !important on specific implementation if required.
  // Also, max(16px, 1.5em) would be better but not suported well atm [4-5-21]
  font-size: 16px !important;

  &:invalid {
    box-shadow: none; // FF
    outline: none; // MS
  }

  &:focus {
    border: 1px solid var(--brand-secondary-500);
  }

  &::-ms-clear {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    // This prevents the background color from changing and looking a little funny
    -webkit-transition-delay: 99999s;
  }
}

@mixin webkitSpinner {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
}

.number {
  -moz-appearance: textfield;
  @include webkitSpinner;

  &:hover,
  &:focus {
    @include webkitSpinner;
  }
}

.search {
  &::-webkit-search-cancel-button {
    display: none;
  }
}
