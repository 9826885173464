.tabs-v2 {
  border-bottom: $border-quiet;
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;

  li {
    flex: 1 auto;
  }

  a {
    @include font-small;
    color: $brand-quiet;
    display: block;
    padding: $xs-spacing $small-spacing $small-spacing;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}

.tabs-v2__tab--active {
  a {
    border-bottom: 3px solid var(--brand-primary-600);
  }
}
