@import "../../../scss/base/variables";

.root {
  hr {
    color: $heather;
    border-top: none;
    border-bottom: 0.025px solid $gray-16;
    border-right: $gray-16;
    border-left: $gray-16;
    margin: 0px -0.75em $small-spacing -0.75em;
  }
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.triangle {
  font-size: 35px;
  margin-bottom: $small-spacing;
  color: $gray-06;
}

.retryButton {
  margin-top: $base-spacing;
  margin-bottom: $small-spacing;
}

.reachOut {
  line-height: 1;
  margin-top: $base-spacing;
}

.link {
  color: $blue;
}
