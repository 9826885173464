@use '../../../scss/base/variables' as vars;

.toast {
  display: flex;
  align-items: center;
  background-color: var(--brand-surface-elevation-900);
  border: 1px solid var(--brand-primary-border-200);
  border-radius: vars.$medium-border-radius;
  color: var(--brand-neutral-cool-900);
  padding: vars.$small-spacing vars.$medium-spacing;

  -webkit-animation: toast-in-bottom 700ms ease, toast-out-bottom 700ms ease 5000ms;
  animation: toast-in-bottom 700ms ease, toast-out-bottom 700ms ease 5000ms;

  &:not(:last-child) {
    margin: 0 0 vars.$medium-spacing;
  }
}

.toast__content {
  flex: 1 1 auto;
  margin: 0 vars.$small-spacing 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast__action {
  color: var(--brand-secondary-600);
  flex: 0 0 auto;
}

@-webkit-keyframes toast-in-bottom {
	from {
		transform: translateY(100%);
    opacity: 0;
	}
	to {
		transform: translateY(0);
    opacity: 1;
	}
}

@keyframes toast-in-bottom {
	from {
		transform: translateY(100%);
    opacity: 0;
	}
	to {
		transform: translateY(0);
    opacity: 1;
	}
}

@-webkit-keyframes toast-out-bottom {
	from {
		transform: translateY(0);
    opacity: 1;
	}
	to {
		transform: translateY(100%);
    opacity: 0;
	}
}

@keyframes toast-out-bottom {
	from {
		transform: translateY(0);
    opacity: 1;
	}
	to {
		transform: translateY(100%);
    opacity: 0;
	}
}