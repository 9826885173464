@use "src/scss/themes/mixins" as themedMixins;
@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$avatar-border-radius: $border-radius-circle;
$avatar-box-shadow: $base-stroke-shadow;

.root {
  display: inline-block;
  flex: 0 0 auto;
  @include square(70px);
  border: 2px solid var(--brand-hb-white);
  border-radius: $avatar-border-radius;
  @include themedMixins.neutralDown-300;
  overflow: hidden;
}

.img {
  background-size: cover;
  background-position: 50% 15%;
  background-color: var(--brand-primary-custom, var(--brand-primary-600));
  width: 100%;
  height: 100%;
}
