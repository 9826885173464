@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$border-color: var(--brand-primary-border-200);
$box-color-hover: var(--brand-primary-600);

.root {
  @include square(1.25em);
  position: relative;
  border: solid 1px var(--brand-primary-border-200);
  border-radius: $small-border-radius;

  &:hover {
    background: $box-color-hover;

    .icon {
      opacity: .8;
    }
  }

  .icon.selected {
    opacity: 1;
  }
}

$box-color-checked: var(--brand-primary-600);
$box-color-checked-hover: var(--brand-primary-700);

.checked {
  background-color: $box-color-checked;
  border-color: $box-color-checked;

  &:hover {
    background-color: $box-color-checked-hover;
    border-color: $box-color-checked-hover;
  }
}

.focused {
  outline: auto;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  color: white;
  opacity: 0;
}
