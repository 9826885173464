@import '../../../scss/base/variables';

@mixin closeButtonTopRight {
  position: absolute;
  top: $small-spacing;
  right: $small-spacing;
}

.closeButton {
  color: var(--brand-neutral-cool-700);
  font-size: 24px;
}
