ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}

.list-icons {
  li {
    font-size: 14px;
    margin: 0 auto $base-spacing;
    max-width: 90%;
    padding-left: 1.5em;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .icon-li {
    @include position(absolute, 0.2em 0 0 0);
    margin-top: 2px;
    margin-right: $xs-spacing;
    width: 1em;
  }

  a {
    @include truncate-single-line;
    display: block;
    opacity: 0.65;
    transition: color $base-easing $base-duration,
      opacity $base-easing $base-duration,
      transform $base-easing $short-duration;

    &:hover {
      opacity: 1;
      transform: scale(1.025);
    }
  }
}

.list-centered {
  li {
    padding-left: 0;
    text-align: center;
  }

  .icon {
    margin-right: $xs-spacing;
  }
}
