@import '../../../scss/base/variables';

.list {
  display: inline-block;
}

.listItem {
  display: inline-block;

  &:before {
    display: none;
  }

  &:after {
    content: '•';
    display: inline-block;
    padding: 0 $small-spacing;
  }

  &:last-child:after {
    display: none;
  }
}

.listItem.compact:after {
  padding: 0 $xs-spacing;
}
