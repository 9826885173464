@use '../themes/mixins' as themedMixins;

@import '../buyers_mixins';

.chart-tip {
  background: var(--brand-surface-elevation-700);
  border-radius: $base-border-radius;
  color: var(--brand-neutral-cool-900);
  font-size: 14px;
  font-weight: $weight-semibold;
  line-height: 1.3;
  padding: $xs-spacing*0.5 $small-spacing;
  pointer-events: none;
  text-align: center;
  position: relative;
  z-index: 30;
  @include themedMixins.neutralDown-600;
  border: 1px solid var(--brand-primary-border-100);

  em {
    color: var(--brand-primary-600);
    display: block;
  }
}

.chart-tip:after {
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 10px;
}

.chart-tip.n:after {
  border-top-color: var(--brand-surface-elevation-700);
  top: 100%;
  left: 50%;
  margin-left: -10px;
}

.chart-tip.e:after {
  border-right-color: var(--brand-surface-elevation-700);
  top: 50%;
  right: 100%;
  margin-top: -10px;
}

.chart-tip.s:after {
  border-bottom-color: var(--brand-surface-elevation-700);
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
}

.chart-tip.w:after {
  border-left-color: var(--brand-surface-elevation-700);
  top: 50%;
  left: 100%;
  margin-top: -10px;
}

.chart-tip.af {
  em {
    color: var(--brand-neutral-cool-700);
  }

  &.hideMobile {
    @include mobileHeight {
      display: none;
    }
  }
}

.chart-tip.temperature-chart-tip {
  color: var(--brand-neutral-cool-900);
  background: var(--brand-surface-elevation-700);
  max-width: 115px;
  font-size: 13px;
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .15);

  &:after {
    border-top-color: var(--brand-surface-elevation-700);
  }
}
