@use "sass:math";
@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';
@import '../MarketSpotlight/ShouldYouSell/ShouldYouSell.module.scss';

.panel {
  padding: $base-spacing;
  background: var(--brand-surface-elevation-500);
  @include themedMixins.neutralDown-300;
  border-radius: $medium-border-radius;
}

.label {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: -1*$panel-shouldYouSell-margin;
  padding: $panel-shouldYouSell-margin;
}

.labelContent {
  display: flex;
  flex-direction: row;
}

.body {
  margin-top: $base-spacing;
  position: relative;
  display: none;
  overflow: hidden;

  &.active {
    display: block;
  }
}

.toggleButton {
  background: none;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  margin-left: $small-spacing;
  border: none;
  border-radius: 50%;

  &:before {
    content: close-quote;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  $size: 6px;

  &:after {
    content: close-quote;
    border: $size solid var(--brand-neutral-cool-500);
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotateZ(-45deg);
    transform-origin: $size;
    margin-top: math.div($size, -2);
    margin-bottom: $size*0.5;
    transition: transform $base-duration*0.5 $base-easing,
      margin-top $base-duration*0.25 $base-easing,
      margin-bottom $base-duration*0.25 $base-easing,
      background-color $base-duration*0.25 $base-easing;
  }

  &.open:after {
    transform: rotateZ(-45deg) scale(-1);
    margin-top: $size*0.5;
    margin-bottom: math.div($size, -2);
  }

  &:hover {
    background-color: var(--brand-neutral-cool-50);
    border-radius: 50%;

    &:after {
      border: $size solid var(--brand-neutral-cool-600);
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }

  &:active {
    background-color: var(--brand-neutral-cool-100);
  }
}
