@import '../../../scss/base/variables';

.badge {
  display: inline-block;
  border: 1px solid var(--brand-primary-border-50); 
  border-radius: $medium-border-radius;
  padding: $xxs-spacing $xs-spacing;
  background-color: var(--brand-neutral-cool-50);
  color: var(--brand-neutral-cool-900);
  font-size: $xs-font-size;
}

.badge--xs {
  font-size: $xs-font-size;
}

.leadingVisual {
  display: inline-block;
  margin-right: $xs-spacing;
}

