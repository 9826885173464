@use '../../../../scss/base/variables' as vars;

.divider {
  height: 1px;
  margin: vars.$small-spacing 0;
  border-bottom: 1px dashed var(--brand-neutral-cool-200);
}

.divider:after {
  content: "";
  display: block;
  width: 48px;
  border-bottom: 1px solid var(--brand-surface-elevation-100);
  z-index: 3;
}
