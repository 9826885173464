@import "../../../scss/base/variables";

.emailStep {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: $base-spacing;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: $base-spacing;
}
