@import '../../../../scss/base/variables';
@import '../../../../scss/buyers_mixins';

.root {
  line-height: 1.25;
  margin-bottom: $xs-spacing;

  @include smallScreen {
    margin-bottom: $small-spacing;
  }
}
