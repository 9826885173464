@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

.root {
  margin-bottom: $xs-spacing;
}

.label {
  margin: 0;
}

.hide {
  @include hideLabel;
}

.inputAndSuffixWrapper {
  display: flex;
  align-items: center;
  margin-right: .5rem;
}

.suffix {
  margin-left: - $base-spacing;
  z-index: 1;
  color: var(--brand-neutral-cool-600);
}
