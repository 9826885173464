@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.root {
  display: inline;
}

.type-danger {
  .ring {
    fill: var(--brand-danger-500) !important;
  }
}

.type-error {
  .ring {
    fill: var(--brand-warning-500) !important;
  }
}

.type-positive {
  .ring {
    fill: var(--brand-success-700) !important;
  }
}

.quizzical {
  transform: rotate(15deg);
}

.size {
  &-xs {
    @include square(33px);
  }
  &-s {
    @include square(33px);
  }
  &-m {
    @include square(33px);
  }
  &-l {
    @include square(40px);
  }
}

