@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.root {
  background: transparent;
  @include square(40px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: solid $base-border-radius var(--brand-neutral-cool-700);

  i {
    font-weight: $weight-bold;
  }
}

.spinner {
  border: solid $base-border-radius var(--brand-neutral-cool-700);
  border-color: var(--brand-neutral-cool-300);
  border-top-color: var(--brand-neutral-cool-900);
  animation: rotate $base-duration infinite linear;
}

.success {
  border-color: var(--brand-success-700);
}

.failure {
  border: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
