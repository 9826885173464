@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.root.root {
  padding-left: $small-spacing;
  padding-right: $small-spacing;
  background: none;

  &:hover {
    box-shadow: none;
    color: var(--brand-neutral-cool-900);
  }
}
