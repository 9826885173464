@import '../../../scss/base/variables';
@import '../../../scss/lib/animations';

$button-spacingH: 1em;
$button-spacingV: 0.75em;

$button-padding: $button-spacingV $button-spacingH;
$button-padding-compact: 0.5em 0.75em;

$icon-button-size: 2.5em;
$icon-button-size-compact: 2em;
$icon-button-animation: button-pop $base-duration;
$icon-button-padding-compact: 0;

$button-border-radius: 6px;
$button-transition: all $short-duration $base-easing, width 0s;
$button-font-family: $base-font-family;
$button-font-weight: $weight-regular;
$button-outline: none;
$button-shadow: $shadow-small;
$button-icon-margin: $button-spacingV;

$button-animation-hover: none;
$button-box-shadow-hover: $button-shadow;
$button-outline-hover: $button-outline;

$button-animation-active: none;
$button-box-shadow-active: $button-shadow;
$button-outline-active: $button-outline;

$button-animation-focus: none;
$button-box-shadow-focus: $button-shadow;
$button-outline-focus: auto;

$button-animation-delay-loading: $short-duration;
$button-animation-loading: spin infinite $base-duration linear;
$button-border-loading: 2px solid;
// TODO-color:
$button-border-color-loading: $white transparentize($white, 0.75)
  transparentize($white, 0.75);
$button-border-radius-loading: $icon-button-size;
$button-opacity-loading: 0.8;
