@use '../../../scss/themes/mixins' as themedMixins;

@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$box-color: var(--brand-neutral-cool-500);
$box-color-hover: var(--brand-neutral-cool-600);
$box-color-checked: var(--brand-primary-400);
$box-color-checked-hover: var(--brand-primary-300);

$height: 20px;
.root {
  position: relative;
  height: $height;
  width: 2*$height;
  border-radius: 0.5*$height;
  background-color: $box-color;
  flex-shrink: 0;
  transition: background-color $base-easing $base-duration*0.5;

  $borderWidth: 2px;

  .circle {
    box-sizing: border-box;
    background-color: var(--brand-hb-white);
    height: calc(100% - #{2*$borderWidth});
    width: calc(50% - #{2*$borderWidth});
    border: $borderWidth solid transparent;
    border-radius: 100%;
    margin: $borderWidth;
    transition:
      margin-left $base-easing $base-duration*0.5,
      transform  $short-duration $base-easing;
    @include themedMixins.neutralDown-400;
  }

  &:hover {
    opacity: .9;
  }

  &.checked {
    background-color: $box-color-checked;

    .circle {
      margin-left: calc(50% + #{$borderWidth});
    }

  }

  &:active {
    .circle {
      transform: scale3d(.95, .95, 1);
    }
  }
}

.focused {
  outline: auto;
}

.disabled {
  opacity: .5;
}
