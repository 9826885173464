@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.brandingStats {
  display: flex;
  justify-content: center;
  margin: $small-spacing 0;

  .brandingStat {
    display: flex;
    flex-direction: column;
    padding: $xs-spacing $base-spacing;
    width: 155px;

    .number {
      font-size: 20px;
      color: white;
    }

    &:not(:first-of-type) {
      border-left: 1px solid #939393;
    }
  }
}
