@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.mask {
  opacity: 1;
  transition: opacity $base-easing $base-duration*2 $base-duration;
}

.hidden {
  visibility: hidden;
  opacity: .01; // using .01 instead of 0 allows Lighthouse to give correct score for performance metrics (FCP and LCP)
  transition: none;
}

.subdue {
  margin-left: 200vw;
  margin-right: -200vw;
  visibility: hidden;
}

.disableTransition {
  * {
    transition: none !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
}

.htmlFitToViewport {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
}
