@use '../../../../scss/base/variables' as vars;

.qrCode {
  border-radius: 16px;
  padding: vars.$small-spacing;
}

.qrCodeDark {
  background-color: var(--brand-neutral-cool-50);
}

.qrCodeLight {
  background-color: white;
}
