@import '../../../scss/base/variables';

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 2px;
  padding: 7px;

  &-success {
    background: var(--brand-success-50);
  }

  &-warning {
    background: var(--brand-warning-100);
  }
  &-none {
    background: none;
  }
}

.icon {
  align-self: center;
}
