@use '../../../../scss/base/variables' as vars;

.navbarSpinner {
  display: flex;
  align-items: center;
  height: 13px;
  width: 13px;
  margin-right: vars.$xs-spacing;
  border: solid 2px var(--brand-neutral-cool-700);
  border-color: var(--brand-neutral-cool-300);
  border-top-color: var(--brand-neutral-cool-900);
}
