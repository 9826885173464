@use '../themes/mixins' as themedMixins;

$datepicker__background-color: var(--brand-surface-elevation-500) !default;
$datepicker__border-color: var(--brand-neutral-cool-200) !default;

$datepicker__text-color: var(--brand-neutral-cool-900) !default;
$datepicker__header-color: var(--brand-neutral-cool-900) !default;
$datepicker__navigation-disabled-color: var(--brand-neutral-cool-600) !default;

$datepicker__border-radius: 0.1rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__day-padding: 0.5rem !default;
$datepicker__day-hover-color: var(--brand-surface-layer-400) !default;
$datepicker__day-color: var(--brand-secondary-600) !default;
$datepicker__font-size: $base-font-size !default;
$datepicker__font-family: 'Open Sans', Helvetica, Arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

$datepicker__hover-navigation-border-color: var(--brand-neutral-cool-400);

$datepicker__muted-color: var(--brand-neutral-cool-700) !default;
$datepicker__muted-color-10: var(--brand-neutral-cool-800) !default;

$datepicker__highlighted-color: var(--brand-neutral-cool-600) !default;
$datepicker__highlighted-color-5: var(--brand-neutral-cool-800) !default;

$datepicker__selected-color-0: var(--brand-secondary-300) !default;
$datepicker__selected-color: var(--brand-secondary-400) !default;
$datepicker__selected-color-5: var(--brand-secondary-600) !default;

$datepicker__keyboard-selected-background-color: var(
  --brand-neutral-cool-500
) !default;

.show {
  display: block;
}

.hidden {
  display: none;
}

%triangle-arrow {
  margin-left: -$datepicker__triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: '';
    z-index: -1;
    border-width: $datepicker__triangle-size;

    left: -$datepicker__triangle-size;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: $datepicker__background-color;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  background-color: var(--brand-neutral-cool-900);
  color: $datepicker__text-color;
  border-radius: $datepicker__border-radius;
  display: inline-block;
  position: relative;
}

.react-datepicker__input-container input[type='text'] {
  position: relative;
}

.react-datepicker__input-container {
  &:before {
    font-family: 'HomebotWeb';
    position: absolute;
    right: 10px;
    vertical-align: middle;
    content: '\f064';
    top: 8px;
  }
}

.react-datepicker__input-container.hidden {
  &:before {
    display: none;
    content: '\f064';
  }
}

.react-datepicker--time-only {
  .react-datepicker__triangle {
    left: 35px;
  }

  .react-datepicker__time-container {
    border-left: 0;
  }

  .react-datepicker__time {
    border-radius: 0.3rem;
  }

  .react-datepicker__time-box {
    border-radius: 0.3rem;
  }
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 9999999;

  &[data-placement^='bottom'] {
    margin-top: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-up;
    }
  }

  &[data-placement^='top'] {
    margin-bottom: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-down;
    }
  }

  &[data-placement^='right'] {
    margin-left: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }
  }

  &[data-placement^='left'] {
    margin-right: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }
  }
}

.react-datepicker__header {
  text-align: center;
  background-color: $datepicker__background-color;
  border-top-left-radius: $datepicker__border-radius;
  border-top-right-radius: $datepicker__border-radius;
  padding-top: 8px;
  position: relative;

  &--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.react-datepicker__current-month {
  font-size: $datepicker__font-size;
  font-weight: 700;
  margin-bottom: $base-spacing;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker-time__header {
  margin-top: 0;
  color: $datepicker__header-color;
  font-size: $xl-font-size;
  font-weight: 300;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 15px;
  width: 0;
  padding: 0;
  border: $datepicker__navigation-size solid transparent;
  z-index: 1;

  &--previous {
    left: 10px;
    border-right-color: $datepicker__highlighted-color;

    &:hover {
      background-color: transparent;
      border-color: transparent;
      border-right-color: $datepicker__hover-navigation-border-color;
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--next {
    right: 10px;
    border-left-color: $datepicker__highlighted-color;
    &--with-time:not(&--with-today-button) {
      right: 80px;
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
      border-left-color: $datepicker__hover-navigation-border-color;
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
      border-top-color: $datepicker__muted-color;

      &:hover {
        border-top-color: $datepicker__muted-color-10;
      }
    }

    &-upcoming {
      top: -4px;
      border-bottom-color: $datepicker__muted-color;

      &:hover {
        border-bottom-color: $datepicker__muted-color-10;
      }
    }
  }
}

.react-datepicker__month-container {
  float: left;
  background-color: $datepicker__background-color;
  border: 1px solid var(--brand-primary-border-50);
  @include themedMixins.neutralDown-500;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
  border: 1px solid $datepicker__border-color;
  border-radius: 8px;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;
  width: 70px;

  &--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: white;

    .react-datepicker__time-box {
      width: 70px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 30px;
        width: 100%;
        box-sizing: content-box;

        li.react-datepicker__time-list-item {
          padding: 5px 10px;
          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }
          &--selected {
            background-color: $datepicker__selected-color;
            color: white;
            font-weight: bold;
            &:hover {
              background-color: $datepicker__selected-color;
            }
          }
          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
  &.react-datepicker__week-number--clickable {
    cursor: pointer;
    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
}

.react-datepicker__day {
  cursor: pointer;
  font-weight: 700;
  font-size: $datepicker__font-size;

  &:hover {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__day-hover-color;
  }

  &--today {
    font-weight: bold;
    color: $datepicker__day-color;
    &:hover {
      background-color: $datepicker__day-hover-color;
    }
  }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color: var(--brand-neutral-cool-900);

    &:hover {
      background-color: $datepicker__highlighted-color-5;
    }

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--outside-month {
    color: transparent;
    cursor: not-allowed;
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: var(--brand-hb-white);

    &:hover {
      background-color: $datepicker__keyboard-selected-background-color;
    }
  }

  &--selected {
    border-radius: 50%;
    &:hover {
      background-color: $datepicker__selected-color;
      border-radius: 50%;
    }
  }

  &--keyboard-selected {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__keyboard-selected-background-color;
    color: $datepicker__text-color;
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: $datepicker__muted-color-10;
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    border-top-color: $datepicker__muted-color;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: $datepicker__navigation-size;
  }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: $datepicker__border-radius;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: $datepicker__muted-color-10;
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: $datepicker__muted-color-10;
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;

  &::after {
    background-color: $datepicker__selected-color;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: var(--brand-neutral-cool-900);
    content: '\00d7';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $shade-16;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker-time__header {
    font-size: $datepicker__font-size * 1.6;
    font-weight: 300;
  }

  .react-datepicker__navigation {
    border: 1.8 * $datepicker__navigation-size solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $datepicker__muted-color;

    &:hover {
      border-right-color: $datepicker__muted-color-10;
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: $datepicker__muted-color;

    &:hover {
      border-left-color: $datepicker__muted-color-10;
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }
}
