@import './variables.module.scss';

.root {
  position: relative;
}

.top {
  @include peekTop;
}

.right {
  @include peekRight;
}

.left {
  @include peekLeft;
}
