@import '../../../scss/base/variables';
@import './control_variables.module';

.root {
  @include control;
  position: relative;
  border: 1px solid var(--brand-primary-border-100);
  padding: 0;
  border-radius: $medium-border-radius;
}

.input {
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  width: 100%;

  &:hover,
  &:focus {
    background: none;
  }

  &:focus {
    border: 1px solid var(--brand-secondary-500);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
}
