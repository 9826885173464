@import "../../../scss/base/variables";
@import "../../../scss/base/mixins";

$medium-screen: 640px;
// Search Animation
$base-duration: 0.35s;
$base-easing: ease-out;
$value-easing: ease-in-out;
$short-duration: 0.15s;
$long-duration: $base-duration * 2;
$xl-duration: 1s;

$card-background-color: $purple-02;
$dark-tip-background-color: darken($card-background-color, 5%);

$grey-1: rgba($heather, 0.4);
$grey-2: rgba($heather, 1);

$thumb-size: 56px;
$thumb-border: none;
$thumb-cursor: pointer;

$track-height: 8px;
$track-cursor: $thumb-cursor;
$track-radius: 5px;
$track-background-color: $grey-1;
$track-fill-color: $grey-2;
$track-width: 100%;

$circle-size: 16px;

$output-color: $track-background-color;
$output-tip-half-width: 5px;
$output-tip-height: $output-tip-half-width + 1px;

@mixin track() {
  box-sizing: border-box;
  border: none;
  cursor: $track-cursor;
  height: $track-height;
  width: $track-width;
}

@mixin thumb {
  @include square($thumb-size);
  box-shadow: none;
  border: $thumb-border;
  border-radius: $thumb-size;
  background: transparent;
  cursor: $thumb-cursor;
}

.root {
  padding-top: $base-spacing * 1.4;
}

.slider {
  position: relative;
  min-height: $thumb-size;

  .input {
    display: block;
    position: absolute;
    -webkit-appearance: none;
    background-color: transparent;
    margin: 0;
    width: $track-width;
    padding: 0;
    min-height: $thumb-size;
    z-index: 1;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--val) - var(--min)) / var(--range));
    --intersection: calc(
      0.5 *#{$thumb-size} + var(--ratio) * (100% - #{$thumb-size})
    );

    &::-webkit-slider-runnable-track {
      @include track;
    }
    &::-moz-range-track {
      @include track;
    }
    &::-ms-track {
      @include track;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      border-radius: $track-radius;
      background: transparent;
    }

    &::-webkit-slider-thumb {
      @include thumb;
      -webkit-appearance: none;
      position: relative;
      top: -1 * ($thumb-size - $track-height) * 0.5;
    }

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-ms-thumb {
      @include thumb;
      @include square($thumb-size - 8px);
      margin: 4px;
    }

    &::-moz-focus-outer {
      border: none;
    }

    &::-ms-tooltip {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }
}

.output {
  display: block;
  position: absolute;
  top: -1 * ($output-tip-height + $circle-size + 10px);
  padding: $xxs-spacing $small-spacing;
  border-radius: 3px;
  background: $output-color;
  transform: translateX(-50%);

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: $output-tip-height solid $output-color;
    border-left: $output-tip-half-width solid transparent;
    border-right: $output-tip-half-width solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -1 * $output-tip-half-width;
  }
}

.caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 $thumb-size * 0.5;
}

.progress {
  height: $track-height;
  background: $track-fill-color;
  position: absolute;
  left: $thumb-size * 0.5;
  top: ($thumb-size - $track-height) * 0.5;
  border-radius: $track-radius 0 0 $track-radius;
}

.svg {
  position: absolute;
  top: ($thumb-size - $circle-size) * 0.5;
  @include square($circle-size);
  overflow: visible;
}

.focusRing {
  fill: none;
  stroke: $grey-1;
  stroke-width: 0;
  r: 1;
  transition: all $base-duration $base-easing;

  &.focused {
    r: 1.6;
    stroke-width: 1;
  }
}

.circle {
  stroke: $grey-2;
  stroke-width: 0.3;
  fill: rgba($grey-2, 0);
  r: 1;
  transition:
    r $short-duration $base-easing,
    fill $short-duration $base-easing $short-duration;
  &.active {
    fill: $grey-2;
    r: 1.4;
    transition:
      r $short-duration $base-easing,
      fill 0s $base-easing;
  }
}

.background {
  background: $track-background-color;
  height: $track-height;
  position: absolute;
  right: $thumb-size * 0.5;
  top: ($thumb-size - $track-height) * 0.5;
  border-radius: 0 $track-radius $track-radius 0;
}

:export {
  circleSize: #{$circle-size};
  thumbSize: #{$thumb-size};
}
