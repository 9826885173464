@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

.root.root {
  margin-right: $xs-spacing;
}

.hide {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}
