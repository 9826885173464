.icon--tilt {
  transform: rotate(15deg);
}

.icon-bot-head-colored {
  @include square(1em);
  background-image: url(#{$assets_base}img/bot-head-no-circle.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50%;
  border: 2px solid var(--brand-primary-600);
  border-radius: 10em;
  display: inline-block;
  padding: 0.2em;

  &.icon-bot-head-colored--info {
    border-color: var(--brand-warning-500);
    transform: rotate(15deg);
  }

  &.icon-bot-head-colored--danger {
    // background-position: 10% 10%; // drunken bot version
    border-color: $brand-danger;
    transform: rotate(220deg);
  }
}

.help-icon-large {
  color: transparentize($white, 0.75);
  font-size: 0.77em;
  transition: color $base-duration $base-easing;
}

.number:hover .help-icon-large {
  color: $action-color;
}

.number .help-icon-large {
  margin: 0 -1.5em 0 0.1em;
  position: relative;
  top: -0.1em;
}

.spinning-border {
  @include spinning-border;
}

.card,
.modal {
  .close {
    @include position(absolute, $xs-spacing $small-spacing auto auto);
    color: $brand-quiet;
    font-size: 24px;
  }
}

.selectorSection.active {
  a {
    &:hover,
    &:focus,
    &.active {
      color: #fff !important;
      i {
        color: #fff !important;
      }
      background-color: #302e35 !important;
    }
  }
}
