.intro {
  @include row();
  margin: $xl-spacing auto 0;
  max-width: $content-width-max;
  @include font-small();

  .explain {
    margin-bottom: $small-spacing;
  }

  .explain + .explain {
    margin-top: (-$xs-spacing);
  }

  .contact-details {
    margin-bottom: 0;
  }

  .contact-details li {
    line-height: $small-spacing;
    display: inline-block;
  }

  .list-disc {
    color: $brand-quiet;
  }

  .link--subtle {
    color: inherit;
    transition: color $base-easing $base-duration;
  }

  &:hover .link--subtle {
    color: $action-color;
  }

  figure {
    margin-top: 0;

    .photo {
      @include square(6em);
      @include circle-photo;
      margin: 0 auto;
    }
  }

  .intro-explain {
    margin: 0 20px 0;
    padding-top: $small-spacing;
    text-align: center;
  }

  @media (max-width: $medium-screen) {
    .list-disc li:before {
      display: none;
    }
  }

  @media (min-width: $medium-screen) {
    & {
      display: flex;
      justify-content: center;
    }

    .intro-photo {
      flex: 2 0 auto;
      margin-left: -5%;
      text-align: right;

      figure,
      .photo {
        margin-right: 0;
      }
    }

    .intro-explain {
      flex: 4 1 auto;
      margin-right: -10%;
      padding-top: 0;
      text-align: left;

      .explain {
        max-width: 400px;
      }
    }

    .contact-details li {
      line-height: $large-spacing;
    }
  }
}
