@import '../../../scss/base/variables';
@import '../../../scss/buyers_mixins';

$logo-image-size: 33px;
$logo-spacing-mobile: 6px;
$logo-spacing-small: 12px;
$logo-spacing-medium: 0;
$logo-text-font-family: $base-font-family;
$logo-text-font-size: 28.8px;
$logo-text-font-weight: $weight-light;
$logo-text-line-height: $base-line-height;

$text-width: 119px; // visually measured;
$text-spacing: 12px;

$logo-width-mobile: $logo-image-size + $logo-spacing-mobile;
$logo-width-small: $logo-image-size + $logo-spacing-small;
$logo-width-medium: $logo-image-size + $logo-spacing-medium + $text-width + 2 *
  $text-spacing;

.logo {
  display: flex;
  align-items: center;
}

.image {
  width: $logo-image-size;
  height: $logo-image-size;
  min-width: $logo-image-size;
  min-height: $logo-image-size;
  margin-right: $logo-spacing-mobile;

  @include smallScreen {
    margin-right: $logo-spacing-small;
  }
}

.text {
  display: none;
  padding: 0 $text-spacing;
  color: var(--brand-neutral-cool-900);
  font-family: $logo-text-font-family;
  font-size: $logo-text-font-size;
  font-weight: $logo-text-font-weight;
  line-height: $logo-text-line-height;
  -webkit-font-smoothing: antialiased;

  @include largeScreen {
    display: block;
  }
}
