@use "sass:math";
$assets_base: "~src/assets/homes/";

// Typography
$base-font-family: "Open Sans", sans-serif;
$monospace-font-family: "Operator Mono", "Fira Code Retina",
  "Bitstream Vera Sans Mono", ui-monospace, Menlo, Monaco, "Cascadia Mono",
  "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace",
  "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
$heading-font-family: $base-font-family;
$icon-font-family: HomebotWeb;

// Font Sizes
$smallest-font-size: 0.5em; // 8px
$tiny-font-size: 0.6875em; // 11px
$xxs-font-size: 0.75em; // 12px
$xs-font-size: 0.8125em; // 13px
$small-font-size: 0.875em; // 14px
$base-font-size: 1em; // 16px
$l-font-size: 1.125em; // 18px
$xl-font-size: 1.5em; // 24px
$xxl-font-size: 2.25em; // 36px
$xxxl-font-size: 3em; // 48px
$small-number-font-size: 2.25em; // 36px
$number-font-size: em(48);
$graph-label-font-size: 13px;
$form-field-font-size: 16px;

// Font Weights
$weight-light: 300;
$weight-regular: 400;
$weight-semibold: 600;
$weight-bold: 700;
$base-font-weight: $weight-regular;
$weight-normal: $weight-regular;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.33333333;

// Other Sizes
$border-radius-subtle: 1px;
$base-border-radius: 3px;
$xs-border-radius: 4px;
$small-border-radius: 6px;
$medium-border-radius: 8px;
$large-border-radius: 16px;
$xl-border-radius: 24px;

$base-spacing: $base-line-height * 1em; // 24
$medium-spacing: math.div($base-spacing, 1.5); // 16
$small-spacing: $base-spacing * 0.5; // 12
$xs-spacing: $small-spacing * 0.5; // 6
$xxs-spacing: math.div($small-spacing, 3); // 4
$xxxs-spacing: math.div($small-spacing, 12); // 1
$large-spacing: $base-spacing * 1.5; // 36
$xl-spacing: $base-spacing * 2; // 48
$xxl-spacing: $base-spacing * 3; // 72
$xxxl-spacing: $base-spacing * 4; // 96
$section-spacing: $xxl-spacing; // 72
$global-nav-height: 60px;
$action-bar-height: 89px;

// Neat Breakpoints
$small-screen: 420px;
$medium-screen: 640px;
$large-screen: 860px;
$xl-screen: 1200px;
$xxl-screen: 1500px;

$medium-screen-up: new-breakpoint(min-width $medium-screen 4);
$large-screen-up: new-breakpoint(min-width $large-screen 8);

// Gradients
$market-temp-gradient: linear-gradient(
  to right,
  var(--brand-market-temp-cold) 0%,
  var(--brand-market-temp-cool) 27.08%,
  var(--brand-market-temp-neutral) 53.12%,
  var(--brand-market-temp-warm) 74.48%,
  var(--brand-market-temp-hot) 96.35%
);

$market-temp-middle-container-gradient: linear-gradient(
  to right,
  var(--brand-market-temp-cool) 0%,
  var(--brand-market-temp-warm) 96.35%
);

// Z-index
$base-z-index: 0;
$z-1: 10;
$z-2: 20;
$z-3: 30;
$z-4: 40;
$z-5: 50;
$z-6: 60;
$z-7: 70;
$z-8: 80;
$z-modal: $z-3;
$z-spotlight: $z-modal - 1;
$z-page-notification: $z-5;
$z-ceiling: 16777271; // Safari 3 max

// Colors
$blue: #41b9fa;
$light-blue: #0696e3;
$green: #3ce967;
$red: #ff4545;
$redorange: #ef5d58;
$white: #f3eff1;
$bg-white: rgba(255, 255, 255, 0.95);
$dark-blue: #1c293a;
$black: #000000;
$darkish-blue: lighten($dark-blue, 10%);
$darker-blue: shade($dark-blue, 20%);

// Blue Ramp
$blue-01: #0696e3;
$blue-02: #06a3f7;
$blue-03: #41b9fa;

// Purple Ramp
$purple-01: #26252a;
$purple-02: #302e35;
$purple-03: #322b41;
$purple-04: #4a4455;

$dark-purple: $purple-02;
$purple: $purple-03;

// Gray Ramp
$gray-03: #666270;
$gray-04: #787878;
$gray-05: #838383;
$gray-06: #bbb2b7;
$gray-07: #444444;
$gray-08: #9d97ab;
$gray-09: #16141d;
$gray-10: #645c6e;
$gray-11: #55525e;
$gray-12: #d4d4d4;
$gray-13: #3a383f;
$gray-14: #1a1920;
$gray-15: #38363d;
$gray-16: #48464e;

$quiet: $gray-06;
$heather: $gray-08;
$metal: $gray-05;
$secondary-text: $gray-06;
$grid-color: var(--brand-neutral-cool-400);
$light-gray-on-white: $gray-04;
$graph-label-color: $gray-05;
$chart-tick-line-color: darken($graph-label-color, 15);
$light-gray: $gray-06;

// Opacities
$opacity-00: 0;
$opacity-01: 0.05;
$opacity-02: 0.1;
$opacity-03: 0.15;
$opacity-04: 0.2;
$opacity-05: 0.25;
$opacity-06: 0.3;
$opacity-07: 0.35;
$opacity-08: 0.4;
$opacity-09: 0.45;
$opacity-10: 0.5;
$opacity-11: 0.55;
$opacity-12: 0.6;
$opacity-13: 0.65;
$opacity-14: 0.7;
$opacity-15: 0.75;
$opacity-16: 0.8;
$opacity-17: 0.85;
$opacity-18: 0.9;
$opacity-19: 0.95;
$opacity-20: 1;

// Shades
$shade-00: rgba(0, 0, 0, $opacity-00);
$shade-01: rgba(0, 0, 0, $opacity-01);
$shade-02: rgba(0, 0, 0, $opacity-02);
$shade-03: rgba(0, 0, 0, $opacity-03);
$shade-04: rgba(0, 0, 0, $opacity-04);
$shade-05: rgba(0, 0, 0, $opacity-05);
$shade-06: rgba(0, 0, 0, $opacity-06);
$shade-07: rgba(0, 0, 0, $opacity-07);
$shade-08: rgba(0, 0, 0, $opacity-08);
$shade-09: rgba(0, 0, 0, $opacity-09);
$shade-10: rgba(0, 0, 0, $opacity-10);
$shade-11: rgba(0, 0, 0, $opacity-11);
$shade-12: rgba(0, 0, 0, $opacity-12);
$shade-13: rgba(0, 0, 0, $opacity-13);
$shade-14: rgba(0, 0, 0, $opacity-14);
$shade-15: rgba(0, 0, 0, $opacity-15);
$shade-16: rgba(0, 0, 0, $opacity-16);
$shade-17: rgba(0, 0, 0, $opacity-17);
$shade-18: rgba(0, 0, 0, $opacity-18);
$shade-19: rgba(0, 0, 0, $opacity-19);
$shade-20: rgba(0, 0, 0, $opacity-20);

// Brand
$brand-danger: $red;
$brand-quiet: $light-gray;
$brand-xs-quiet: #9d97ab;

// Font Colors
$base-background-color: #222;
$action-color: $blue;

// Other brands
$brand-core-logic: #e53f24;
$brand-realty-trac: #ac24e5;
$brand-zillow: #0074e4;

// Backgrounds
$card-background-color: $purple-02;
$dark-tip-background-color: darken($card-background-color, 5%);
$table-row-background-color: #2b2930;
$light-grey-background: #3a383f;
$bg-grey: #2a2832;

// Border
$base-border-color: var(--brand-primary-border-100);
$base-border: 1px solid $base-border-color;
$thicker-base-border: 2px solid $base-border-color;
$border-quiet: 1px solid transparentize($brand-xs-quiet, 0.66);
$thicker-border-quiet: 2px solid transparentize($brand-xs-quiet, 0.66);
$border-circle: 2px $white solid;
$border-radius-circle: 100%;

$timeline-color: $brand-xs-quiet; //lighten($card-background-color, 20);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus:
  $form-box-shadow,
  0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
$input-pad-vert: 4px;
$input-background-color: rgba(255, 255, 255, 0.05);
$input-background-color-focused: #3d3b43;

// Structure
$content-width-max: 600px;
$large-content-width-max: $content-width-max * 1.25;
$narrow-content-width-max: $content-width-max * 0.9;
$xl-content-width-max: $content-width-max * 2;

// Cards
$card-pad-horizontal: $small-spacing;
$card-pad-horizontal-lg: $base-spacing;
$card-pad-vertical: $large-spacing;
$card-pad-vertical-lg: $xl-spacing;

// Shadows
$shadow-small: 0 1px 2px $shade-03;
$medium-shadow: 0 2px 10px $shade-03;
$large-space-shadow: 0 2px 18px $shade-07;
$card-shadow: $large-space-shadow;
$base-stroke-shadow: 0 1px 3px $shade-05;

// Animation
$short-duration: 0.15s;
$base-duration: 0.35s;
$base-easing: ease-out;
$long-duration: $base-duration * 2;
$xl-duration: 1s;
$value-easing: ease-in-out;
$value-duration: $xl-duration;

// 3D
$perspective: 600px;

// Basic Sizes
$callout-icon-size: 42px;

// Chartist
$ct-grid-color: $grid-color;
$ct-text-color: $graph-label-color;
$ct-text-size: em(13);
$ct-line-width: 3px;
$ct-line-dasharray: true;
$ct-point-size: 0;

$ct-series-names: (a, AVM, c, d, e, f, g, h, i, j, k, l, m, n, o);
$ct-series-colors: (
  #d70206,
  var(--brand-primary-600),
  #f4c63d,
  #d17905,
  #453d3f,
  #59922b,
  #0544d3,
  #6b0392,
  #f05b4f,
  #dda458,
  #eacf7d,
  #86797d,
  #b2c326,
  #6188e2,
  #a748ca
);

// Layout
$nav-header-height: 60px; // remove
$impersonation-banner-height: 40px; // remove
$nav-header-height-with-impersonation-banner: $nav-header-height +
  $impersonation-banner-height; // remove
$mobile-nav-header-height: 63px; // remove
$header-max-width: 1200px;
$buyer-dashboard-width: 800px;
$home-dashboard-width: 600px;
