@import "../../../scss/base/variables";

.magicLinkStep {
  margin-top: $base-spacing;
}

.helper {
  margin-bottom: $base-spacing;
}

.sentTo {
  margin-bottom: $base-spacing;

  button {
    margin-top: $xs-spacing;
  }
}

.btnRow {
  display: flex;
  flex-direction: row;

  a {
    margin: $xs-spacing;
  }
}
