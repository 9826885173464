@import '../../../scss/base/variables';

$button-group-spacing: $small-spacing * 0.5;

.root {
  margin: -1 * $button-group-spacing;
  > * {
    margin: $button-group-spacing;
  }
}

.align {
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
  }
  &-justify {
    text-align: justify;
  }
  &-start {
    text-align: start;
  }
  &-end {
    text-align: end;
  }
}
