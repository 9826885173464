@import '../../../../scss/base/variables';

$panel-shouldYouSell-margin: $base-spacing;
.panel {
  margin: $panel-shouldYouSell-margin 0;
}

.header {
  margin: $base-spacing auto $large-spacing auto;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  text-align: left;
}

.icon {
  margin-right: $small-spacing;
  margin-top: $xxs-spacing;
  border-radius: $small-border-radius;
}

.videoHeader {
  margin: 2em 0 1em 0;
}

.bottomButton {
  margin-top: $base-spacing;
}
