@import '../../../scss/base/variables';

$half-button: 20px;

$text-area-background-color: $shade-06;
$text-area-color: $white;
$text-area-font-family: $base-font-family;
$text-area-font-size: 15px;
$text-area-font-weight: $weight-regular;
$text-area-padding: 10px $small-spacing;
$text-area-placeholder-color: $light-gray;
$avatar-dimensions: 28px;
$avatar-padding: $small-spacing;

.question {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 4px;
}

.focused {
  outline: 1px auto $white;
  outline: 1px auto -webkit-focus-ring-color;
}

.textarea {
  flex: 1;
  border: none;
  background: var(--brand-surface-layer-50);
  color: var(--brand-neutral-cool-900);
  font-family: $text-area-font-family;
  font-size: $text-area-font-size;
  font-weight: $text-area-font-weight;
  overflow-x: hidden;
  overflow: hidden;
  padding: $text-area-padding;
  resize: none;
  width: 100%;
  word-wrap: break-word;
  border-radius: $small-border-radius;
  margin-right: 0.5rem;
  border: 1px solid var(--brand-primary-border-100);

  &:focus {
    outline: none;
  }
}

.withAvatar {
  position: relative;

  .textarea {
    padding-left: calc(2 *#{$small-spacing} + #{$avatar-dimensions});
    padding-right: calc(20px + #{$small-spacing});
  }

  .avatar {
    width: $avatar-dimensions;
    height: $avatar-dimensions;
    position: absolute;
    top: auto;
    bottom: auto;
    left: $small-spacing;
  }
}
