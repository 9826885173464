@use '../../../scss/base/variables' as vars;
@use '../../../scss/themes/mixins' as themedMixins;
@use '../../../scss/buyers_mixins' as buyersMixins;

.root {
  background-color: var(--brand-surface-elevation-500);
  @include themedMixins.neutralDown-500;
  height: vars.$nav-header-height;
  min-height: vars.$nav-header-height;
  padding-left: vars.$small-spacing;
  padding-right: vars.$small-spacing;
  position: relative;
  z-index: 9;

  @include buyersMixins.mediumScreen {
    padding-left: vars.$base-spacing;
    padding-right: vars.$base-spacing;
  }
}
