@import "../../../scss/base/variables";
@import "../../../scss/base/mixins";

// based on the stlyes from web for page-notification
.notification {
  @include reveal-vert;

  bottom: auto;
  box-shadow: $large-space-shadow;
  color: var(--brand-hb-white);
  font-size: $l-font-size;
  left: 0;
  margin-bottom: 0;
  overflow: visible;
  padding: $base-spacing $base-spacing;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: all $base-easing $base-duration;
  cursor: pointer;

  &.hide {
    visibility: hidden;
    padding: 0;
    opacity: 0;
    transform: translateY(1em);
  }

  &.success {
    background: var(--brand-success-300);
  }

  &.error {
    background: var(--brand-primary-600);
  }

  .icon {
    display: inline-block;
    margin-right: $small-spacing;
  }
}
