@import '../../../scss/base/variables';

.childWrapper {
  position: relative;
  display: inline-block;

  .confettiHolder {
    position: absolute;
    width: 0;
    height: 0;
  }
}
