@use '../base/variables' as vars;

@keyframes dashoffset {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -20px;
  }
}

.fade {
  opacity: 0;
  transition: opacity vars.$base-easing vars.$base-duration;

  &.in,
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes button-pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loading-bar {
  0% {
    height: 10%;
  }
  25% {
    height: 20%;
  }
  50% {
    height: 14%;
  }
  75% {
    height: 18%;
  }
  100% {
    height: 10%;
  }
}
