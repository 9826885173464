@import '../base/variables';

.line-chart {
  height: 100%;
  shape-rendering: geometricPrecision;
  width: 100%;

  .line {
    fill: none;
    stroke: var(--brand-primary-600);
    stroke-width: 3;
  }

  .line--connector {
    stroke-dasharray: 5, 3;
  }

  .point {
    cursor: pointer;
    fill: var(--brand-primary-600);
  }

  .point-border {
    cursor: pointer;
    fill: $card-background-color;
    stroke-width: 1;
    stroke: var(--brand-primary-600);
  }

  .photo-border {
    fill: transparent;
    stroke: $white;
    stroke-width: 3;
  }

  .icon {
    display: inline-block;
    font: normal normal normal 14px/1 $icon-font-family;
    font-size: inherit;
    font-weight: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .axis {
    .tick text {
      display: none;
      fill: $graph-label-color;
      font-size: 13px;
    }

    .tick:first-of-type text,
    .tick:last-of-type text {
      display: block;
    }

    @media (min-width: $medium-screen) {
      .tick text {
        display: block;
      }
    }
  }

  .axis--x {
    .domain,
    .tick line {
      display: none;
    }
  }

  .axis--y {
    .domain {
      // line that would run up the side of graph
      display: none;
    }
  }
}
