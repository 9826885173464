@import "../../../scss/base/variables";
@import "../../../scss/buyers_mixins";
@import "../../../scss/lib/animations";

$ring-offset: 0px;
$ring-size: 50px;

.root {
  position: relative;
  @include square($ring-size);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    top: $ring-offset;
    left: $ring-offset;
    right: $ring-offset;
    bottom: $ring-offset;
  }
}

.color-blue:after {
  background: url("#{$assets_base}img/avatar-border-dotted.svg") 50% 0 no-repeat;
  background-size: contain;
}

.color-white:after {
  background: url("#{$assets_base}img/ring-border-dotted-white.svg") 50% 0
    no-repeat;
  background-size: contain;
}

.animate:after {
  animation: spin 30s linear infinite;
}
