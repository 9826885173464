.bar-chart {
  height: 100%;
  shape-rendering: geometricPrecision;
  width: 100%;
}

.bar-chart__bar {
  fill: var(--brand-neutral-cool-300);
  &:hover,
  &.active {
    fill: var(--brand-primary-600);
  }
}

.affordability {
  .bar-chart__bar {
    fill: var(--brand-success-200);
    &:hover,
    &:focus,
    &.active {
      fill: var(--brand-success-600);
    }
    &.future {
      fill: var(--brand-neutral-cool-300);
      &:hover {
        fill: var(--brand-neutral-cool-300);
      }
    }
  }
  .chart-tip.fo {
    padding: 0.175em 0.75em;
  }
}

.bar-chart__axis {
  .tick text {
    fill: var(--brand-neutral-cool-700);
    font-size: $graph-label-font-size;
    font-family: $base-font-family;
    font-weight: $weight-semibold;
  }
}

.bar-chart__axis--x {
  .tick:nth-of-type(2n) text {
    display: none;
  }
  .domain,
  .tick line {
    display: none;
  }
}

.bar-chart__axis--y {
  .tick line,
  .domain {
    // line that would run up the side of graph
    display: none;
  }
}

.affordability-bar-chart {
  .bar-chart__axis--y .tick line {
    stroke: var(--brand-primary-border-500);
    stroke-dasharray: 1, 4;
    display: block;
  }
}
