@use "sass:math";
@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

.root {
  margin-bottom: 1em;
}

.counter {
  background-color: var(--brand-surface-layer-400);
  font-size: 3.5em !important;
  font-weight: $weight-light;
  width: 2.5em;
  margin-right: math.div($small-spacing, 5);
  padding: 0 0 0 0.2em;
  line-height: 0;
  caret-color: transparent;
}

.block {
  display: flex;
  margin-top: 5px;
}

.incrementorButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plus,
.minus {
  font-size: $small-font-size;
  padding: 0.7em 0.75em;
}

.plus {
  margin-bottom: $xxs-spacing;
}
