@import '../../../../scss/base/variables';

$time-interval-slow: 850ms;
$time-interval-medium: 500ms;
$time-interval-fast: 250ms;
$time-interval-zero: 0ms;

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: height $base-easing, opacity $base-easing;

  &.hidden {
    opacity: 0;

    > :not(.mask) {
      visibility: hidden !important;
      transition: none !important;

      * {
        visibility: hidden !important;
        transition: none !important;
      }
    }

    .mask * {
      transition: none !important;
    }
  }

  &.slow {
    &,
    .mask {
      transition-duration: $time-interval-slow;
    }
  }
  &.medium {
    &,
    .mask {
      transition-duration: $time-interval-medium;
    }
  }
  &.fast {
    &,
    .mask {
      transition-duration: $time-interval-fast;
    }
  }
  &.zero {
    &,
    .mask {
      transition-duration: $time-interval-zero;
    }
  }

  textarea {
    resize: none;
  }
}

.mask {
  position: absolute;
  top: 0;
  width: 100%;

  visibility: visible;
  opacity: 0;
  transition: opacity $base-easing;

  &.hidden {
    visibility: hidden;
    opacity: 1;
  }
}

:export {
  timeInterval-slow: #{$time-interval-slow};
  timeInterval-medium: #{$time-interval-medium};
  timeInterval-fast: #{$time-interval-fast};
  timeInterval-zero: #{$time-interval-zero};
}
