.disclaimer {
  margin-bottom: $base-spacing;
  text-align: center;
  max-width: 600px;
}

.disclaimer-body p {
  text-align: left;
}

.disclaimer-body strong {
  font-weight: $weight-bold;
}
