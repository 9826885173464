@use '../../../../scss/base/variables' as vars;
@use '../ListingsCarousel.module.scss' as carouselVars;

.homeIcon {
  font-size: vars.$xl-font-size;
}

.lastSlideText {
  padding: 10px 30px;
}

.lastListingContainer {
  height: 100%;
  margin: 0 auto;
  width: carouselVars.$image-width;

  & > a {
    display: flex;
    flex-direction: column;
  }
}
